import angular from 'angular';
import 'core/services/apiService.js';
import 'core/services/dataService.js';
import 'core/services/sessionService.js';
import '../core/services/dialog.factory';
import './users.less';
import { view as editUserView } from './edit-user/edit-user.view';
import { decorateUser } from '../core/utils/UserUtils';

export function UsersController(apiService, dataService, sessionService, dialogFactory, routeService) {

   const vm = this;

   vm.users = [];
   vm.filters = {
      role: 'any',
      locale: 'any',
      application: 'any',
      search: ''
   };
   vm.init = init;
   vm.onSortBy = onSortBy;
   vm.onEditUser = onEditUser;
   vm.onCreateUser = onCreateUser;
   vm.getRole = (user) => user.roles.length ? user.roles[0].display_name : '';
   vm.getApplications = (user) => {
      if (vm.applications && vm.applications.length === user.applications.length) {
         return 'All';
      }

      if (user.applications.length === 1) {
         return user.applications[0].displayName;
      }

      if (user.applications.length === 0) {
         return '';
      }

      return user.applications.length;
   };
   vm.getMarkets = (user) => {
      if (vm.locales.length === user.locales.length) {
         return 'All';
      }

      if (user.locales.length === 1) {
         return user.locales[0];
      }

      if (user.locales.length === 0) {
         return '';
      }

      return user.locales.length;
   };
   vm.getApplicationsList = user => user.applications.map(a => a.displayName);
   vm.getMarketsList = user => user.locales;

   vm.customSort = {
      roles: item => item.roles.length ? item.roles[0].display_name : '',
      applications: item => item.applications.length,
      markets: item => item.locales.length
   };

   Object.defineProperty(vm, 'computedUsers', {
      get() {
         const users = vm.users.filter(user => filterUser(user));

         users.sort((a, b) => vm.reversed ? a.username - b.username : b.username - a.username);

         return users;
      }
   });

   function init() {
      vm.user = sessionService.getUser();

      if (!vm.user.can('read', 'user')) {
         routeService.forbidden();
      }

      vm.locales = dataService.getData().locales;
      dataService.getApplications().then(res => vm.applications = res.data.Results);
      dataService.getRoles().then(data => vm.roles = data);
      getUsers();
      onSortBy('username');
   }

   function getUsers() {
      apiService.getUsers()
         .then(function (xhr) {
            vm.users = xhr.data.Results.map(decorateUser);
         });
   }

   function filterUser(user) {
      return userMatchesRole(user)
         && userMatchesLocale(user)
         && userMatchesApplication(user)
         && userMatchesSearch(user);
   }

   function userMatchesSearch(obj) {
      const properties = ['username', 'email'];

      return properties.some(p => obj[p].toLocaleLowerCase().indexOf(vm.filters.search.toLocaleLowerCase()) > -1);
   }

   function userMatchesApplication(user) {
      if (vm.filters.application === 'any') { return true; }

      return user.applications.find(app => app.id === vm.filters.application);
   }

   function userMatchesRole(user) {
      if (vm.filters.role == 'any') {
         return true;
      }

      return user.roles.find(role => role.name === vm.filters.role);
   }

   function userMatchesLocale(user) {
      if (vm.filters.locale == 'any') {
         return true;
      }

      if (user.locales.includes(vm.filters.locale)) {
         return true;
      }

      return false;
   }

   function onSortBy(prop) {
      if (vm.orderBy === prop) {
         vm.reversed = !vm.reversed;
      } else {
         vm.orderBy = prop;
         vm.reversed = false;
      }
   }

   function onEditUser(user) {
      if (
         vm.user.id === user.id || // You should not be able to edit yourself
         !vm.user.can('edit', 'user')
      ) {
         return dialogFactory.alert({
            header: 'Forbidden',
            body: 'You are not authorized to edit this user'
         });
      }

      dialogFactory.custom(Object.assign({
         header: 'Edit user ' + user.username,
         data: {
            user: angular.copy(user),
            locales: vm.locales,
            applications: vm.applications,
            roles: vm.roles
         },
         closeOnClickOutside: false,
         proceedCallback: onEditUserCallback
      }, editUserView));
   }

   function onCreateUser() {
      if (!vm.user.can('create', 'user')) {
         return dialogFactory.alert({
            header: 'Forbidden',
            body: 'You are not authorized to create user'
         });
      }

      dialogFactory.custom(Object.assign({
         header: 'Create user',
         data: {
            user: {
               id: null,
               username: '',
               email: '',
               password: '',
               confirm_password: '',
               locales: [],
               applications: [],
               roles: []
            },
            locales: vm.locales,
            applications: vm.applications,
            roles: vm.roles
         },
         closeOnClickOutside: false,
         proceedCallback: onEditUserCallback
      }, editUserView));
   }

   function onEditUserCallback(data) {
      if (data) {
         getUsers();
      }
   }

}

UsersController.$inject = ['apiService', 'dataService', 'sessionService', 'dialogFactory', 'routeService'];
import './char-length.less';

export function CharLengthDirective() {
   return {
      restrict: 'EA',
      link: function(scope, element, attr) {
         if ((!attr.useWhen || attr.useWhen === 'true') && attr.charLimit > 0) {
            const container = document.createElement('div');
            const charLength = document.createElement('span');
            const charLimit = document.createElement('span');
            let limit = 1000;

            container.className = 'char-length-container';
            container.appendChild(charLength);
            container.appendChild(charLimit);

            setTimeout(() => {
               const input = element[0].querySelector('input,textarea');
               if (input) {
                  input.addEventListener('focus', () => {
                     container.classList.add('active');
                  });
                  input.addEventListener('blur', () => {
                     container.classList.remove('active');
                  });
               }
            });

            attr.$observe('charValue', function(value) {
               charLength.textContent = value.length;
               if (value.length > limit) {
                  container.classList.add('char-error');
                  container.setAttribute('title', 'It is recommended to verify the translation in an application context if it has exceeded the character limit, as it might break the design.');
               } else {
                  container.classList.remove('char-error');
                  container.setAttribute('title', 'The value is within the recommended character limit.');
               }
            });

            attr.$observe('charLimit', function(value) {
               charLimit.textContent = ' / ' + value;
               limit = parseInt(value);
            });

            element[0].insertBefore(container, element[0].firstChild);
            element.addClass('char-limit-enabled');
         }
      }
   };
}

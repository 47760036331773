import angular from 'angular';

const moduleName = 'stateService';
const storedApplication = localStorage.getItem('application');

// global statets
const states = {
   application: storedApplication ? storedApplication : null,
   auth: null,
   hasChanges: false,
   loading: false,
   locale : localStorage.getItem('locale'),
   localRevision: null,
   masterRevision: null,
   marketRevision: null,
   newOrUpdated: localStorage.getItem('newOrUpdated') === 'true' ? true : false,
   itemStatus: localStorage.getItem('itemStatus') || null,
   searchString: localStorage.getItem('searchString'),
   path : localStorage.getItem('path'),
   redirect: '/translate',
   reference: 'master',
   referenceMarket: null,
   environment: null,
   versionName: null,
   authExpiresAtUTC: null,
   loginFailCount: 0
};

export default moduleName;

angular.module(moduleName, [])
   .service(moduleName, stateService);

function stateService() {

   this.getStates = getStates;
   this.getState = getState;
   this.setState = setState;
   this.setStates = setStates;
   this.clearState = clearState;
   this.clearLocalStorage = clearLocalStorage;

   function getStates() {
      return states;
   }

   function getState(prop) {
      if (states[prop] === null) {
         states[prop] = localStorage.getItem(prop);
      }
      return states[prop] || null;
   }

   function setState(prop, value, store) {
      if (typeof states[prop] === 'undefined') {
         console.error('state ' + prop + ' is not valid');
         return;
      }
      states[prop] = value;
      if (store) {
         localStorage.setItem(prop, value);
      }
   }

   function setStates(_states) {
      for (const state in _states) {
         setState(state, _states[state].value, _states[state].store);
      }
   }

   function clearState(prop) {
      states[prop] = null;
      if (localStorage.getItem(prop)) {
         localStorage.removeItem(prop);
      }
   }

   function clearLocalStorage() {
      const props = ['locale', 'newOrUpdated', 'itemStatus', 'searchString', 'path'];

      for (const prop of props) {
         clearState(prop);
      }
   }
}

import angular from 'angular';

import { TranslateController } from './translate.controller';
import { OptionsMenuDirective } from './options-menu.directive';
import { localValueChangeController } from './local-value-change/local-value-change.view';

import template from './translate.template.html';

angular.module('translate', [
   'stateService',
   'dataService',
   'dialogFactory',
   'components',
   'apiService',
   'sessionService',
   'publish'
])
   .controller('translateController', TranslateController)
   .directive('optionsMenu', OptionsMenuDirective)
   .controller('LocalValueChangeCtrl', localValueChangeController);

export const view = {
   controller: 'translateController',
   controllerAs: 'vm',
   template
};
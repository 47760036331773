import angular from 'angular';
import constants from '../constants';
import {modalView as loginView} from '../../login/login.module';
import './dialog.factory.js';

const moduleName = 'apiService';
const apiBaseUrl = 'api/';
const revisionUrl = apiBaseUrl + 'translations/version/';
const authUrl = apiBaseUrl + 'auth/';
const publishUrl = apiBaseUrl + 'publish/';
//const publishUrl = '/pub/api/publish/';
const translationsUrl = apiBaseUrl + 'translations/';
const masterUrl = apiBaseUrl + 'master/';
const appsUrl = apiBaseUrl + 'applications/';
const usersUrl = apiBaseUrl + 'users';
const rolesUrl = apiBaseUrl + 'acl/roles/';

export default moduleName;

angular.module(moduleName, [
   'dialogFactory'
])
   .service(moduleName, apiService);

function apiService($http, stateService, dialogFactory, toastersFactory, analyticsService) {

   $http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

   this.authenticate = authenticate;
   this.login = login;
   this.logout = logout;
   this.getRevisions = getRevisions;
   this.getRevision = getRevision;
   this.saveLocalization = saveLocalization;
   this.saveMaster = saveMaster;
   this.moveKey = moveKey;
   this.getLatest = getLatest;
   this.getMaster = getMaster;
   this.getPublishStatus = getPublishStatus;
   this.publish = publish;
   this.getPublishedVersion = getPublishedVersion;
   this.getBuildHistory = getBuildHistory;
   this.getApplications = getApplications;
   this.getApplication = getApplication;
   this.saveApplication = saveApplication;
   this.getUsers = getUsers;
   this.getUser = getUser;
   this.saveUser = saveUser;
   this.deleteUser = deleteUser;
   this.updatePassword = updatePassword;
   this.getRoles = getRoles;
   
   window.apiService = {
      moveKey: moveKey
   };

   function authenticate() {
      return getRequest(authUrl);
   }

   function login(username, password) {
      return postRequest(authUrl, {
         username: username,
         password: password
      }).then(res => {
         analyticsService.trackEvent('user', 'login');
         return res;
      });
   }

   function logout() {
      return delRequest(authUrl).then(res => {
         analyticsService.trackEvent('user', 'logout');
         return res;
      });
   }

   function getRevisions(application, locale) {
      return getRequest(translationsUrl + 'revisions/', {
         params: {
            application: application,
            locale: locale
         }
      });
   }

   function getRevision(application, locale, revision) {
      return getRequest(translationsUrl, {
         params: {
            application: application,
            locale: locale,
            revision: revision
         }
      });
   }

   function getLatest(application, locale) {
      return getRequest(translationsUrl, {
         params: {
            application: application,
            locale: locale
         }
      });
   }

   function getMaster(application, revision, excludeUnapproved) {
      return getRequest(masterUrl, {
         params: {
            application,
            revision,
            excludeUnapproved
         }
      });
   }

   function saveLocalization(application, locale, data, lastRevision) {
      return postRequest(translationsUrl, {
         application: application,
         locale: locale,
         data: data,
         lastRevision: lastRevision
      }).then(res => {
         analyticsService.trackEvent('translate', 'save', `${application}|${locale}`);
         return res;
      });
   }

   function saveMaster(application, data) {
      return postRequest(masterUrl, {
         application: application,
         data: data
      }).then(() => {
         toastersFactory.show({
            layout: 'success',
            body: 'Your changes have been saved'
         });
         stateService.setState('hasChanges', false);
         analyticsService.trackEvent('master', 'save', `${application}|global`);
      });
   }

   function moveKey(from, to) {
      return postRequest(masterUrl + 'move/', {
         fromApplication:  from.application,
         fromSection:      from.section,
         fromLabel:        from.label,
         toApplication:    to.application,
         toSection:        to.section
      });
   }

   function getPublishStatus(job, application) {
      return $http.get(publishUrl, {
         params: {
            action: 'lastBuild',
            job: job,
            appName: application
         }
      });
   }

   function publish(job, application, locale, revision, allLocales) {
      return postRequest(publishUrl, {
         job,
         application,
         locale,
         revision,
         allLocales
      }).then(res => {
         const category = locale === 'global' ? 'master' : 'translate';
         analyticsService.trackEvent(category, 'publish', `${application}|${locale}|${job}`);
         return res;
      });
   }

   function getBuildHistory(params) {
      return getRequest(publishUrl + 'jobs/', {
         params: params
      });
   }

   function getPublishedVersion(application, locale, environment) {
      return $http.get(revisionUrl, {
         params: {
            application: application,
            environment: environment,
            locale: locale,
            timestamp: new Date().getTime()
         }
      });
   }

   function getRoles() {
      return getRequest(rolesUrl);
   }

   function getApplications() {
      return getRequest(appsUrl);
   }

   function getApplication(id) {
      return getRequest(appsUrl, {
         params: {
            id: id
         }
      });
   }

   function saveApplication(id, data) {
      return postRequest(appsUrl, data).then(res => {
         return res;
      });
   }

   function getUsers() {
      return getRequest(usersUrl);
   }

   function getUser(id) {
      return getRequest(`${usersUrl}/${id}`);
   }

   function saveUser(data) {
      if (!data.id) {
         return createUser(data).then(res => {
            return res;
         });
      }

      return updateUser(data).then(res => {
         return res;
      });
   }

   function createUser(data) {
      return postRequest(usersUrl, data);
   }

   function updateUser(data) {
      return putRequest(usersUrl, data);
   }

   function deleteUser(id) {
      return delRequest(`${usersUrl}/${id}`).then(res => {
         return res;
      });
   }

   function updatePassword(id, password) {
      return putRequest(usersUrl + 'password/', {
         id,
         password
      });
   }

   function getRequest(url, options) {
      const request = $http.get(url, options);
      request.catch(onRequestError);
      return request;
   }

   function postRequest(url, options) {
      const request = $http.post(url, options);
      request.catch(onRequestError);
      return request;
   }

   function putRequest(url, options) { 
      const request = $http.put(url, options);
      request.catch(onRequestError);
      return request;
   }

   function delRequest(url, options) {
      const request = $http.delete(url, options);
      request.catch(onRequestError);
      return request;
   }

   function onRequestError(xhr) {
      console.log('req error', xhr);
      if (xhr.status === 401) {
         if (stateService.getState('auth') === constants.SESSION_AUTHENTICATED) {
            stateService.setState('auth', constants.SESSION_TIMED_OUT);
            loginView.data = loginView.data || {};
            loginView.data.reloadOnSuccess = xhr.config.method === 'GET';
            dialogFactory.custom(loginView);
         }
      } else if (xhr.status === 422) {
         return xhr;
      } else if (xhr.config.method === 'POST') {
         toastersFactory.show({
            layout: 'failure',
            body: 'If this problem persists, please contact the support.',
            closeable: true
         });
      }
   }

}

apiService.$inject = ['$http', 'stateService', 'dialogFactory', 'toastersFactory', 'analyticsService'];

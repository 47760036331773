import template from './unpublished-changes.template.html';
import './unpublished-changes.less';

export const view = {
   header: 'Unpublished changes',
   template
};

export function UnpublishedChangesController() {

   const vm = this;

   vm.init = init;
   vm.isType = isType;
   vm.onConfirm = confirmAndClose;
   vm.onCancel = close;

   function init(dialog) {
      vm.dialog = dialog;
      vm.changes = Object.assign({}, dialog.data.changes);
   }

   function isType(data, prop, type) {
      if (data && typeof data[prop] === type) {
         return true;
      }
      return false;
   }

   function close() {
      vm.dialog.close();
   }

   function confirmAndClose() {
      vm.dialog.proceedCallback();
      vm.dialog.close();
   }
}

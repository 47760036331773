import angular from 'angular';
import constants from '../constants';
import './dialog.factory.js';
import './sessionService';
import './stateService';

const moduleName = 'routeService';
const paths = ['/translate', '/master', '/publish-history', '/users', '/documentation', '/admin'];

const unsavedChangesMessage = 'Do you want to leave this page? \n\nChanges you made will not be saved.';

export default moduleName;

angular.module(moduleName, [
   'dialogFactory',
   'sessionService',
   'stateService'
])
   .service(moduleName, routeService);

function routeService($route, $location, $rootScope, dialogFactory, sessionService, stateService) {

   const states = stateService.getStates();

   this.getPath = getPath;
   this.setPath = setPath;
   this.redirect = redirect;
   this.forbidden = forbidden;

   $rootScope.$on('$routeChangeStart', function (event, next) {
      if (states.auth !== constants.SESSION_AUTHENTICATED && next.originalPath !== '/auth') {
         event.preventDefault();
         setPath('/auth');
      }
   });

   $rootScope.$on('$routeChangeSuccess', function (current, next) {
      if (next.originalPath !== states.path) {
         setPath(next.originalPath);
      }
   });

   function getPath() {
      return stateService.getState('path');
   }

   function setPath(path) {
      if (getPath() !== path) {
         if (!states.hasChanges || confirm(unsavedChangesMessage)) {
            stateService.setState('path', path, (paths.indexOf(path) > -1));
            $location.path(path);
         }
      }
   }

   function redirect() {
      const path = stateService.getState('redirect');
      setPath(path && path !== '/auth' ? path : '/translate');
      stateService.clearState('redirect');
   }

   function forbidden() {
      setPath('/403');
   }

}

routeService.$inject = [
   '$route',
   '$location',
   '$rootScope',
   'dialogFactory',
   'sessionService',
   'stateService'
];

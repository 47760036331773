import template from './edit-application.template.html';
import './edit-application.less';
import deployTargets from 'core/configs/deployTargets.js';
import 'core/services/apiService.js';
import 'core/services/sessionService.js';
import 'core/services/stateService.js';
import '../../core/services/dialog.factory';

export const view = {
   template: template
};

export function EditApplicationController($scope, apiService, sessionService, stateService, toastersFactory) {

   const vm = this;

   vm.states = stateService.getStates();
   vm.user = sessionService.getUser();
   vm.hasChanged = false;

   vm.init = init;
   vm.onItemChanged = onItemChanged;
   vm.onItemBlur = onItemBlur;
   vm.onConfirm = confirmAndClose;
   vm.onCancel = close;
   vm.errors = null;

   vm.getError = (field) => {
      const errors = vm.errors[field];

      if (errors && errors.length) {
         return errors[0].includes('These rules must pass') ? errors[1] : errors[0];
      }

      return null;
   };

   function init(dialog) {
      vm.dialog = dialog;
      getApplication();
   }

   function getApplication() {
      apiService.getApplication(vm.states.application)
         .then((xhr) => {
            vm.item = xhr.data;
            vm.hasChanged = false;
         }).catch((e) => {
            console.log('getApplication error', e);
         });
   }

   function onItemChanged () {
      vm.item.changed = true;
      vm.hasChanged = true;
   }

   function onItemBlur(prop) {
      let arr = vm.item[prop].split(',');

      // trim
      arr.forEach(function(item, index) {
         arr[index] = item.trim();
      });

      // remove invalid
      if (prop === 'deployTargets') {
         arr.forEach(function(item, index) {
            if (!deployTargets.some(function(target) {
               return target.id === item;
            })) {
               arr.splice(index, 1);
            }
         });
      } else if (prop.indexOf('Options') > 0) {
         const options = ['xlsx', 'json'];
         arr.forEach(function(item, index) {
            if (!options.includes(item)) {
               arr.splice(index, 1);
            }
         });
      }

      // remove duplicates
      arr = arr.filter(function(item, pos) {
         return arr.indexOf(item) == pos;
      });

      vm.item[prop] = arr.join(',');
   }

   function close() {
      vm.dialog.close();
   }

   function confirmAndClose() {
      vm.errors = null;
      apiService.saveApplication(vm.states.application, vm.item)
         .then((xhr) => {
            vm.dialog.proceedCallback({
               item: xhr.data
            });
            vm.dialog.close();
            toastersFactory.show({
               layout: 'success',
               body: 'Application has been updated'
            });
         })
         .catch(xhr => {
            vm.errors = xhr.data.errors;
         });
   }
}

EditApplicationController.$inject = [
   '$scope',
   'apiService',
   'sessionService',
   'stateService',
   'toastersFactory'
];


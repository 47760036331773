import angular from 'angular';
import template from './admin.template.html';
import { AdminController } from './admin.controller';
import { MoveKeyController } from './move-key/move-key.view';

angular.module('admin', [
   'dataService',
   'dialogFactory',
   'sessionService',
   'stateService'
])
   .controller('admin', AdminController)
   .controller('MoveKeyCtrl', MoveKeyController);

export const view = {
   controller: AdminController,
   controllerAs: 'vm',
   template
};

import 'core/services/apiService.js';
import '../../core/services/dialog.factory';
import './move-key.less';
import template from './move-key.template.html';

export const view = {
   header: 'Move key',
   template: template
};

export function MoveKeyController(apiService, dialogFactory) {

   const vm = this;
   vm.dialog = null;

   vm.init = init;
   vm.onSelectApplication = onSelectApplication;
   vm.onSelectSection = onSelectSection;
   vm.onConfirm = moveKey;
   vm.onCancel = onCancel;
   vm.isValid = isValid;
   vm.isLoading = false;

   function init(options) {
      vm.dialog = options;
      vm.move = {
         from: {
            application: null,
            section: null,
            label: null
         },
         to: {
            application: null,
            section: null
         }
      };
      vm.data = {};
      getApplications();
   }

   function getApplications() {
      apiService.getApplications()
         .then(xhr => {
            vm.applications = xhr.data.Results;
         });
   }

   function onSelectApplication(app) {
      if (!vm.data[app]) {
         apiService.getMaster(app)
            .then(xhr => {
               vm.data[app] = xhr.data;
               if (vm.move.from.application === app) {
                  for (const section in xhr.data) {
                     vm.move.from.section = section;
                     onSelectSection();
                     break;
                  }
               }
               if (vm.move.to.application === app && !xhr.data[vm.move.to.section]) {
                  for (const section in xhr.data) {
                     vm.move.to.section = section;
                     break;
                  }
               }
            });
      }
   }

   function onSelectSection() {
      if (!vm.move.to.section) {
         vm.move.to.section = vm.move.from.section;
      }
      const sectionKeys = [];
      for (const key in vm.data[vm.move.from.application][vm.move.from.section]) {
         sectionKeys.push(key);
      }
      vm.sectionKeys = sectionKeys.sort();
   }

   function moveKey() {
      if (isValid()) {
         vm.isLoading = true;
         apiService.moveKey(vm.move.from, vm.move.to)
            .then(xhr => {
               console.log(xhr);
               vm.isLoading = false;
               dialogFactory.alert({
                  header: 'Operation success',
                  body: 'The operation was completed successfully!\n'
               + (xhr.data.locales.length
                  ? 'The following markets have been updated:\n' + formatLocales(xhr.data.locales) + '.'
                  : 'No markets were affected.')
               });
            }, xhr => {
               dialogFactory.alert({
                  header: 'Operation failed',
                  body: 'The operation failed to execute. ' + xhr.status + ', ' + xhr.statusText
               });
            });
      } else {
         console.log('operation is not valid');
      }
   }

   function formatLocales(locales) {
      let localesString = '';
      let count = 0;
      for (const locale of locales) {
         if (count < 6) {
            if (count > 0) {
               localesString += ', ';
            }
            localesString += ', ' + locale;
            count++;
         } else {
            localesString += '\n' + locale;
            count = 0;
         }
      }
      return localesString;
   }

   function isValid() {
      for (const key in vm.move) {
         for (const prop in vm.move[key]) {
            if (!vm.move[key][prop] || vm.move[key][prop] === 'unselected') {
               console.log('vm.move[' + key + '][' + prop + '] is : ', vm.move[key][prop]);
               return false;
            }
         }
      }
      return true;
   }

   function onCancel() {
      vm.dialog.close();
   }
}

MoveKeyController.$inject = [
   'apiService',
   'dialogFactory'
];

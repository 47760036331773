import angular from 'angular';

const moduleName = 'dialogFactory';

export default moduleName;

angular.module(moduleName, [])
   .factory(moduleName, DialogFactory);

function DialogFactory() {

   const dialogs = [];
   let dispatcher = () => {
      throw new Error('Missing handler function');
   };

   return {
      alert: function(options) {
         return open('alert', options);
      },
      confirm: function(options) {
         return open('confirm', options);
      },
      custom: function(options) {
         return open('custom', options);
      },
      getOptions: getOptions,
      close: close,
      closeAll: closeAll,
      getDialogs: getDialogs,
      getCurrentDialog: getCurrentDialog,
      connect: connect
   };

   function connect(handler) {
      dispatcher = handler;
   }

   function open(type, options) {
      options.id = String(new Date().getTime());
      options.type = type || 'alert';
      options.closeButton = options.closeButton !== false;
      options.closeOnClickOutside = options.closeOnClickOutside || false;
      options.maxSize = options.maxSize || false;
      options.header = options.header || false;
      options.body = options.body || '';
      options.data = options.data || {};
      options.proceedText = options.proceedText || 'OK';
      options.proceedCallback = options.proceedCallback || null;
      options.cancelText = options.cancelText || 'Cancel';
      options.cancelCallback = options.cancelCallback || null;
      options.template = options.template || null;
      options.zIndex = dialogs.length > 0 ? dialogs[dialogs.length - 1].zIndex + 1 : 100;
      options.close = close;
      options.autoClose = options.autoClose || false;
      show(options);
      return options;
   }

   function getOptions(id) {
      for (let i = 0, l = dialogs.length; i < l; i++) {
         if (dialogs[i].id == id) {
            return dialogs[i]; //angular.copy(dialogs[i]);
         }
      }
      return null;
   }

   function show(options) {
      dialogs.push(options);
      dispatcher('show');

      if (options.autoClose) {
         setTimeout(function() {
            close(options.id);
         }, options.autoClose);
      }
   }

   function close(id) {
      for (let i = 0, l = dialogs.length; i < l; i++) {
         if (dialogs[i].id == id) {
            dialogs.splice(i, 1);
            break;
         }
      }
      dispatcher('close');
   }

   function closeAll() {
      dialogs.splice(0);
      dispatcher('closeAll');
   }

   function getDialogs() {
      return dialogs;
   }
   
   function getCurrentDialog() {
      return dialogs[dialogs.length - 1]; 
   }

}

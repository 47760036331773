import './dialogs-container.less';
import template from './dialogs-container.template.html';

export function DialogsContainerDirective($timeout, dialogFactory) {
   return {
      restrict: 'E',
      template: template,
      link: function(scope, element) {
         scope.dialogs = dialogFactory.getDialogs();
         dialogFactory.connect(update);

         element.on('click', onClickOutside);

         function update() {
            element.toggleClass('visible', scope.dialogs.length > 0);
            $timeout(function() {
               scope.$apply();
            }, 0);
         }

         function onClickOutside() {
            const dialog = scope.dialogs[scope.dialogs.length - 1];
            if (dialog && dialog.closeOnClickOutside) {
               dialogFactory.closeAll();
            }
         }

      }
   };
}

DialogsContainerDirective.$inject = ['$timeout', 'dialogFactory'];
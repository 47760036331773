export function diffingUtilFactory() {
   return {
      squashTablesToCommon
   };

   function squashTablesToCommon({ primaryTableOnGroups, secondaryTableOnGroups }) {
      let commonTable = mapPrimaryTableToCommon(primaryTableOnGroups);
      commonTable = mapSecondaryTableToCommon(commonTable, primaryTableOnGroups, secondaryTableOnGroups);
      commonTable = sortItemsAlphabetically(commonTable);
      return addDiffCountToEachLabel(commonTable);
   }

   function addDiffCountToEachLabel(commonTable) {
      return commonTable.map((labelGroup)=> {
         labelGroup.diffCounter = 0;
         labelGroup.items.forEach((item)=> {
            if ((item.primaryTable && item.primaryTable.value) !== (item.secondaryTable && item.secondaryTable.value)) {
               labelGroup.diffCounter++;
            } else if ((item.primaryTable && !item.secondaryTable) || (!item.primaryTable && item.secondaryTable)) {
               labelGroup.diffCounter++;
            }
         });
         return labelGroup;
      });
   }

   function sortItemsAlphabetically(table) {
      return table.map((labelGroup) => {
         labelGroup.items = labelGroup.items.sort((a, b) => {
            if (a.key < b.key) {return -1;}
            if (a.key > b.key) {return 1;}
            return 0;
         });
         return labelGroup;
      });
   }

   function mapPrimaryTableToCommon(primaryTableOnGroups) {
      // Add all label groups and items in primary table to each group in to a common list for both tables.
      return primaryTableOnGroups.map((groupPrimaryTable) => {
         return {
            label: groupPrimaryTable.label,
            items: mapItemsAsPrimaryTable(groupPrimaryTable.items)
         };
      });
   }

   function mapItemsAsPrimaryTable(items) {
      return items.map(item => {
         return {
            key: item.key,
            primaryTable: item
         };
      });
   }

   function mapSecondaryTableToCommon(commonTable, primaryTableOnGroups, secondaryTableOnGroups) {
      secondaryTableOnGroups.forEach((groupSecondaryTable) => {
         commonTable = addMathingItemsFromSecondaryGroupToCommonTable(commonTable, primaryTableOnGroups, groupSecondaryTable);
         groupSecondaryTable.items = removeMathingItemsFromSecondaryTableGroup(primaryTableOnGroups, groupSecondaryTable);
         addNonMatchingItemsFromSecondaryGroupToCommonTable(commonTable, groupSecondaryTable);
      });

      return commonTable;
   }

   function addMathingItemsFromSecondaryGroupToCommonTable(commonTable, primaryTableOnGroups, groupSecondaryTable) {
      // Check what items in the group for secondary table that matches any items in the same group on primary table.
      const matchingItems = groupSecondaryTable.items.filter((itemSecondary) =>
         primaryTableOnGroups.some(
            (groupPrimaryTable) => {
               if (groupSecondaryTable.label === groupPrimaryTable.label) {
                  return groupPrimaryTable.items.some(
                     (itemPrimary) => itemPrimary.key === itemSecondary.key
                  );
               }
               return false;
            }
         )
      );

      // Add the matching items to each row in the commonTable list
      // the has items with same keys in the primaryTable prop
      primaryTableOnGroups.forEach((groupPrimaryTable) => {
         groupPrimaryTable.items.forEach((item) => {
            const itemMatchingCurrentPrimaryItem = matchingItems.filter(currentItem =>
               currentItem.key === item.key && currentItem.labelGroup === item.labelGroup
            )[0];

            if (itemMatchingCurrentPrimaryItem) {

               // Add the matching item to the row with the item with same key
               // TODO: Huh?? since if section ends with return true does that
               // mean this only executes for first match of label and then exits?
               // Is there only ever one label match?

               // eslint-disable-next-line
               commonTable.some(labelGroup => {
                  if (labelGroup.label === groupSecondaryTable.label) {
                     labelGroup.items.forEach((labelGroupItem) => {
                        if (labelGroupItem.primaryTable.key === itemMatchingCurrentPrimaryItem.key) {
                           labelGroupItem.secondaryTable = itemMatchingCurrentPrimaryItem;
                           labelGroupItem.isDiffing = labelGroupItem.primaryTable.value !== labelGroupItem.secondaryTable.value;
                        }
                     });
                     return true;
                  }
               });
            }
         });
      });

      return commonTable;
   }

   function removeMathingItemsFromSecondaryTableGroup(primaryTableOnGroups, groupSecondaryTable) {
      // Remove the items from the secondary table, that we just added to the commonTable.
      // TODO: Huh?? This should be cleaned up when I figure out what is going on
      return groupSecondaryTable.items.filter((itemSecondary) =>
         !primaryTableOnGroups.some(
            (groupPrimaryTable) => {
               if (groupSecondaryTable.label === groupPrimaryTable.label) {
                  return groupPrimaryTable.items.some(
                     (itemPrimary) => itemPrimary.key === itemSecondary.key
                  );
               } else { return false; }
            }
         )
      );
   }

   function addNonMatchingItemsFromSecondaryGroupToCommonTable(commonTable, groupSecondaryTable) {
      const items = groupSecondaryTable.items.map(item => {
         return {
            key: item.key,
            secondaryTable: item
         };
      });

      // If the label already exists in commonTable list
      // Add secondary table items to that lists items.
      // TODO: Huh?? This should be cleaned up when I figure out what is going on
      const foundExistingLabel = commonTable.some(labelGroup => {
         if (labelGroup.label === groupSecondaryTable.label) {
            items.forEach((item) => labelGroup.items.push(item));
            return true;
         } else { return false; }
      });

      // If the label doesn't exists in commonTable list
      // If not - push a new group to the commonTable list.
      if (!foundExistingLabel) {
         commonTable.push({
            label: groupSecondaryTable.label,
            items
         });
      }
   }
}

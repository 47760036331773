import './highlight-button.less';

export default function highlightButton() {
   return {
      restrict: 'E',
      transclude: true,
      scope: {
         disabled: '<',
         toggledEvent: '&',
      },
      template: `
      <button id="highlight-button" ng-click="onClick()" ng-disabled="disabled">
         <ng-transclude>
         </ng-transclude>
      </button>`,
      link: (scope, element, attrs)=> {
         scope.onClick = ()=> {
            if (attrs.toggledEvent) {
               scope.toggledEvent();
            }
         };
      }
   };
}

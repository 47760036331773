import angular from 'angular';
import template from './documentation.template.html';
import docs from './data.json';
import releaseNotes from './release-notes.json';
import ikeaLocales from '../locales/locales.json';
import '../core/services/sessionService';
import './documentation.less';

export const moduleName = 'documentation';

export const view = {
   controller: DocumentationController,
   controllerAs: 'vm',
   template: template
};

angular.module(moduleName, ['components', 'sessionService'])
   .controller(moduleName, DocumentationController);

function DocumentationController(analyticsService, sessionService) {
   const vm = this;
   const titles = { 
      master: 'Master copy',
      translate: 'Translations',
      notes: 'Release notes'
   };

   const user = sessionService.getUser();
   const hasAdminRights = user.roles.some(role => role.name === 'admin' || role.name === 'master_copy_admin');

   vm.tabsList = [
      {
         key: 0,
         title: 'Translate'
      },
      {
         key: 1,
         title: 'Master copy'
      },
      {
         key: 2,
         title: 'Release notes'
      }
   ];

   if (hasAdminRights) {
      vm.tabsList.push({
         key: 3,
         title: 'Locales'
      });
   }

   vm.filteredResults = {};
   vm.hiddenTabs = [];
   vm.visibleTabs = [];
   vm.tabSelected = 0;

   vm.init = init;
   vm.onSelectSection = setSelected;

   vm.onTabSelected = ({ title }) => {
      analyticsService.trackEvent('documentation', 'change_tab', title);
   };

   function init() {
      vm.docs = docs;
      vm.docs.notes = releaseNotes.notes;
      vm.locales = Object.entries(ikeaLocales).map(([key, value]) => ({ key, value })).sort((a, b) => ((a.value > b.value) ? 1 : -1));
      setSelected('translate', 0);
   }

   function setSelected(section, index) {
      if (typeof index !== 'undefined') {
         vm.selected = {
            section: section,
            title: getTitle(section), 
            header: vm.docs[section][index].title, 
            body: vm.docs[section][index].body
         };
      } else {
         vm.selected.section = section;
      }
   }

   function getTitle(section) {
      titles[section];
   }

}

DocumentationController.$inject = [
   'analyticsService',
   'sessionService',
];

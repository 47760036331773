import angular from 'angular';
import loginDialog from './login.template.html';

angular.module('login', ['components']);

export const modalView = {
   header: 'Your session has timed out',
   template: loginDialog,
   closeButton: false
};

import angular from 'angular';
import template from './users.template.html';

import { UsersController } from './users.controller';
import { EditUserController } from './edit-user/edit-user.controller';

angular.module('users', [
   'apiService',
   'dialogFactory',
   'components'
])
   .controller('users', UsersController)
   .controller('EditUserCtrl', EditUserController);

export const view = {
   controller: UsersController,
   controllerAs: 'vm',
   template
};


import angular from 'angular';
import ikeaLocales from '../../locales/locales.json';
import { format } from '../utils/DateUtils';
import apiService from './apiService';
import sessionService from './sessionService';
import stateService from './stateService';

const moduleName = 'dataService';
const data = {
   applications: {}
};

export default moduleName;

angular.module(moduleName, [
   apiService,
   stateService,
   sessionService
])
   .service(moduleName, dataService);

function dataService($rootScope, apiService, stateService, sessionService, toastersFactory) {

   const self = this;
   const states = stateService.getStates();
   let user;

   self.getData = getData;
   self.getApplications = getApplications;
   self.getRevisions = getRevisions;
   self.getRevision = getRevision;
   self.getLatest = getLatest;
   self.getMaster = getMaster;
   self.updateMasterGroups = updateMasterGroups;
   self.isMasterUpdated = isMasterUpdated;
   self.saveLocalization = saveLocalization;
   self.saveMaster = saveMaster;
   self.getMasterRevision = getMasterRevision;
   self.getRoles = getRoles;
   self.clearRevision = clearRevision;

   (function() {
      const locales = [];
      for (const locale in ikeaLocales) {
         locales.push({
            value: locale,
            label: ikeaLocales[locale]
         });
      }
      data.locales = locales.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
   })();

   function getData() {
      return data;
   }

   function getRoles() {
      return apiService.getRoles()
         .then(res => res.data);
   }

   function getApplications(updated) {
      const request = apiService.getApplications();
      request.then(function(xhr) {
         for (const app of xhr.data.Results) {
            data.applications[app.id] = {
               displayName: app.displayName,
               exportOptions: app.exportOptions.split(','),
               importOptions: app.importOptions.split(','),
               deployTargets: app.deployTargets.split(','),
               isTranslatable: app.isTranslatable,
               showOnlyApproved: app.showOnlyApproved,
               removeEmptyStrings: app.removeEmptyStrings
            };
         }
         if (updated) {
            $rootScope.$broadcast('APPLICATIONS_UPDATED', data.applications);
         }
      });
      return request;
   }

   function getRevisions(namespace, locale, fetchLatest) {
      const revisions = namespace + 'Revisions';
      const req = apiService.getRevisions(states.application, locale);
      req.then(function(xhr) {
         data[revisions] = [];

         for (const revision of xhr.data.revisions) {
            data[revisions].push({
               value: revision,
               label: format(revision)
            });
         }
         if (data[revisions].length) {
            stateService.setState(namespace + 'Revision', data[revisions][0].value, false);

            if (fetchLatest) {
               getRevision(namespace, locale, data[revisions][0].value);
            }
         } else if (namespace === 'market') {
            data[`$${namespace}`] = {};
            data[namespace] = {};
         }

      }, onError);

      return req;
   }

   function getRevision(namespace, locale, revision) {
      const req = apiService.getRevision(states.application, locale, revision);
      data[namespace] = null;
      req.then(function(xhr) {
         data['$' + namespace] = angular.copy(xhr.data);
         data[namespace] = {};
         for (const section in xhr.data) {
            data[namespace][section] = {};
            for (const key in xhr.data[section]) {
               data[namespace][section][key] = {
                  value: xhr.data[section][key],
                  changed: false
               };
               formatValue(namespace, section, key);
            }
         }
         console.log('getRevision data', angular.copy(data));
      }, onError);
      return req;
   }

   function clearRevision(namespace) {
      data[namespace] = {};
      data[`$${namespace}`] = {};
   }

   function getLatest() {
      const started = new Date().getTime();
      const req = apiService.getLatest(states.application, states.locale);
      data.local = null;
      data.$local = null;
      req.then(function(xhr) {
         onLocalData(xhr);
         console.log('get latest took ' + (new Date().getTime() - started) + 'ms');
      }, onError);
      return req;
   }

   function saveLocalization(data) {
      const req = apiService.saveLocalization(states.application, states.locale, data, states.localRevision);
      req.then(onLocalData)
         .then(() => {
            toastersFactory.show({
               layout: 'success',
               body: 'Your changes have been saved.',
               duration: 3000
            });
         }).catch(()=>{
            toastersFactory.show({
               layout: 'failure',
               body: 'Your changes have not been saved.',
               closeable: true
            });
         });
      return req;
   }

   function onLocalData(xhr) {
      const removeEmpty = data.applications[states.application].removeEmptyStrings;
      data.local = {};
      data.$local = {};

      for (const section in xhr.data) {
         data.local[section] = {};
         data.$local[section] = {};

         for (const key in xhr.data[section]) {
            data.local[section][key] = angular.copy(xhr.data[section][key]);
            data.local[section][key].changed = false;
            formatValue('local', section, key);

            data.$local[section][key] = angular.copy(xhr.data[section][key]);
            data.$local[section][key].changed = false;
            formatValue('$local', section, key);

            if (removeEmpty
            && data.local[section][key].value === ''
            && data.master
            && data.master[section]
            && data.master[section][key]
            && data.master[section][key].allowEmpty === false) {
               delete data.local[section][key];
            }
         }
      }
      console.log('getLatest data', angular.copy(data));
   }

   function getMaster(revision, excludeUnapproved = false) {
      data.master = null;
      data.$master = null;
      data.masterGroups = null;
      data.masterSectionCount = {};
      //start = new Date().getTime();
      const req = apiService.getMaster(states.application, revision, excludeUnapproved);
      req.then(onMasterData, onError);
      return req;
   }

   function getMasterRevision(application, revision) {
      const req = apiService.getMaster(application, revision);
      return req;
   }

   function saveMaster() {
      const req = apiService.saveMaster(states.application, data.master);
      req.then(onMasterData, onError);
      return req;
   }

   function onMasterData(xhr) {
      data.master = {};
      data.$master = {};

      for (const section in xhr.data) {
         data.master[section] = {};
         data.$master[section] = {};

         for (const key in xhr.data[section]) {
            data.master[section][key] = angular.copy(xhr.data[section][key]);
            data.master[section][key].changed = false;
            data.master[section][key].labelGroup = data.master[section][key].labelGroup || '';
            data.master[section][key].markets = data.master[section][key].markets || '';
            formatValue('master', section, key);

            data.$master[section][key] = angular.copy(xhr.data[section][key]);
            data.$master[section][key].changed = false;
            formatValue('$master', section, key);
         }
      }
      //('getMaster data', (new Date().getTime() - start), angular.copy(data));
   }

   function itemMatchesSearch(item, searchString, isTranslateView) {
      if (!searchString) {
         return true;
      }

      const properties = ['label', 'description', 'tags', 'key'];

      // If we are in translate view we should ignore master copy value
      // and use whatever the reference is instead. Otherwise one might
      // get confused when using a differente reference but keys appear
      // that match the master copy value.
      if (!isTranslateView) {
         properties.push('value');
      }

      for (let i = 0; i < properties.length; i++) {
         const property = properties[i];
         if (item[property] != null && String(item[property]).toLowerCase().includes(searchString)) {
            return true;
         }
      }

      // TODO: This was the best of all the crappy ways of doing this ._.
      if (isTranslateView) {
         if (data.local &&
            data.local[item.section] &&
            data.local[item.section][item.key] != null
         ) {
            const local = data.local[item.section][item.key];
            if (String(local.value).toLowerCase().includes(searchString)) {
               return true;
            }
         }

         if (states.reference &&
            data[states.reference] &&
            data[states.reference][item.section] &&
            data[states.reference][item.section][item.key] != null
         ) {
            const reference = data[states.reference][item.section][item.key];
            if (String(reference.value).toLowerCase().includes(searchString)) {
               return true;
            }
         }
      }
      
      return false;
   } 

   function updateMasterGroups() {
      const locale = states.locale || 'global';

      user = sessionService.getUser();

      //const started = new Date().getTime();
      const isMasterView = stateService.getState('path') === '/master';
      const countryCode = locale.split('_')[1];
      const searchString = states.searchString ? states.searchString.toLowerCase() : null;
      const approvedOnly = !isMasterView && data.applications[states.application].showOnlyApproved;
      const itemStatusFilter = states.itemStatus;
      data.masterGroups = {};
      data.masterSectionCount = { totalUnset: 0, totalUpdated: 0 };
      data.allLabels = {};

      for (const section in data.master) {
         if (data.master.hasOwnProperty(section)) {
            const labelItems = {};
            data.masterGroups[section] = [];
            data.masterSectionCount[section] = { unset: 0, updated: 0 };
            data.allLabels[section] = [];

            for (const key in data.master[section]) {
               if (data.master[section].hasOwnProperty(key)) {
                  const item = data.master[section][key];
                  const expanded = Object.keys(data.master[section]).length < 500;
                  item.key = key;

                  if (!data.allLabels[section].includes(item.labelGroup)) {
                     data.allLabels[section].push(item.labelGroup);
                  }

                  if (itemMatchesState(item, itemStatusFilter, isMasterView, approvedOnly)
                  && itemMatchesSearch(item, searchString, stateService.getState('path') === '/translate')
                  && (isMasterView || !item.markets || item.markets.split(',').includes(countryCode))
                  && (isMasterView || !states.newOrUpdated || !data.local || !data.local[section] || !data.local[section][key] || isMasterUpdated(section, key))) {
                     item.labelGroup = item.labelGroup || 'Unlabeled';

                     if (!labelItems[item.labelGroup]) {
                        labelItems[item.labelGroup] = { label: item.labelGroup, items: [], limit: 25, expanded: expanded };
                        data.masterGroups[section].push(labelItems[item.labelGroup]);
                     }

                     labelItems[item.labelGroup].items.push(item);
                  }

                  if (data.local) {
                     if (data.master[section].hasOwnProperty(key)
                     && !data.master[section][key].deprecated
                     && (approvedOnly && data.master[section][key].approved || !approvedOnly || (approvedOnly && !data.master[section][key].approved && user.can('read_full', 'translation')))
                     && (!item.markets || item.markets.split(',').includes(countryCode))) {
                        if (!data.local[section] || !data.local[section][key]) {
                           data.masterSectionCount[section].unset++;
                           data.masterSectionCount.totalUnset++;
                        } else if (isMasterUpdated(section, key)) {
                           data.masterSectionCount[section].updated++;
                           data.masterSectionCount.totalUpdated++;
                        }
                     }
                  }
               }
            }

            data.masterGroups[section].sort((a, b) => {
               const aLabel = a.label.toLowerCase();
               const bLabel = b.label.toLowerCase();
               if (aLabel < bLabel) {
                  return -1;
               }
               if (aLabel > bLabel) {
                  return 1;
               }
               return 0;
            });
            if (data.masterGroups[section][0]) {
               data.masterGroups[section][0].expanded = true;
            }

            for (const label of data.masterGroups[section]) {
               label.items.sort((a, b) => {
                  const aKey = a.key.toLowerCase();
                  const bKey = b.key.toLowerCase();
                  if (aKey < bKey) {
                     return -1;
                  }
                  if (aKey > bKey) {
                     return 1;
                  }
                  return 0;
               });
            }
         }
      }
   }

   function itemMatchesState(key, filter, isMasterView, approvedOnly) {
      if (isMasterView) {
         if (filter === 'approved' && !key.approved) {
            return false;
         }
         if (filter === 'notapproved' && key.approved) {
            return false;
         }
         if (filter === 'deprecated' && !key.deprecated) {
            return false;
         }
         if (filter === 'all') {
            return true;
         }
         if (!filter && key.deprecated) {
            return false;
         }
      } else {
         if (key.deprecated || (approvedOnly && !key.approved && !user.can('read_full', 'translation'))) {
            return false;
         }
      }
      return true;
   }

   function isMasterUpdated(section, key) {
      if (!data.local || !data.local[section] || !data.local[section][key]) {
         return false;
      }
      const timestamp = data.local[section][key].updated || states.localRevision;
      if (timestamp < data.master[section][key].updated) {
         return true;
      }
      return false;
   }

   function onError(xhr) {
      console.error('dataService', xhr);
   }

   function formatValue(namespace, section, key) {
      if (
         data &&
         data[namespace] &&
         data[namespace][section] &&
         data[namespace][section][key] &&
         data.master &&
         data.master[section] &&
         data.master[section][key]
      ) {
         if (typeof data[namespace][section][key].value !== typeof data.master[section][key].value) {
            if (data.master[section][key].type === 'integer') {
               data[namespace][section][key].value = Number(data[namespace][section][key].value);
            }
            else if (data.master[section][key].type === 'boolean') {
               data[namespace][section][key].value = (data[namespace][section][key].value === 'true' || data[namespace][section][key].value === '1') ? true : false;
            }
            else {
               data[namespace][section][key].value = String(data[namespace][section][key].value);
            }
         }
      }
   }

}

dataService.$inject = ['$rootScope', 'apiService', 'stateService', 'sessionService', 'toastersFactory'];

import angular from 'angular';
import 'core/services/routeService.js';
import 'core/services/sessionService.js';
import 'core/services/stateService.js';
import '../core/services/dialog.factory';
import './nav-menu.less';
import template from './nav-menu.template.html';
import constants from '../core/constants';

const moduleName = 'navMenu';

export default moduleName;

angular.module(moduleName, [
   'routeService',
   'sessionService',
   'stateService',
   'dialogFactory'
])
   .directive(moduleName, NavMenuDirective)
   .controller(moduleName, NavMenuController);

function NavMenuController($scope, routeService, stateService, sessionService) {
   const vm = this;
   const unsavedChangesMessage = 'Do you want to leave this page? \n\nChanges you made will not be saved.';

   vm.states = stateService.getStates();
   vm.view = routeService.getPath();
   vm.navigateTo = navigateTo;
   vm.logout = logout;

   Object.defineProperties(vm, {
      isLoggedInOrTimedOut: {
         get () {
            const auth = stateService.getState('auth');
            return auth === constants.SESSION_AUTHENTICATED || auth === constants.SESSION_TIMED_OUT;
         }
      },
      currentUser: {
         get () {
            return sessionService.getUser();
         }
      }
   });
   
   attachUnsavedChangesWarning();

   function navigateTo(path) {
      routeService.setPath(path);
   }

   function attachUnsavedChangesWarning() {
      window.addEventListener('beforeunload', function(e) {
         if (!vm.states.hasChanges) {
            return;
         }
         return (e || window.event).returnValue = unsavedChangesMessage;
      });
   }

   function logout() {
      sessionService.logout().then(function() {
         // To reload SPA completely. Otherwise some 
         // information from previously logged in user
         //  might still be present
         window.location.href = '/';
      });
   }
}

NavMenuController.$inject = ['$scope', 'routeService', 'stateService', 'sessionService'];

function NavMenuDirective() {

   return {
      restrict: 'E',
      controller: NavMenuController,
      controllerAs: 'vm',
      template,
      link: function(scope) {

         scope.open = false;

         scope.toggleMenu = function() {
            scope.open = !scope.open;
         };
      }
   };

}

import angular from 'angular';
import template from './master.template.html';

import { MasterViewController } from './master.controller';
import { masterMenuDirective } from './master-menu.directive';
import { EditMasterItemController, AddOptionDirective } from './edit-master-item/edit-master-item.view';
import { EditMasterLabelController } from './edit-master-label/edit-master-label.view';
import { CompareRevisionsCtrl } from './compare-revisions/compare-revisions.view';
import { diffingUtilFactory } from './compare-revisions/diffing-util';
import { revisionListDirective } from './compare-revisions/revision-list/revision-list.directive';
import { AddMasterSectionController } from './add-master-section/add-master-section.view';
import { EditApplicationController } from './edit-application/edit-application.view';
import { ImportDialogCtrl } from './import-dialog/import-dialog.view';
import { importListDirective } from './import-dialog/import-list/import-list.directive';
import { masterDataMappingUtilFactory } from './master-data-mapping-util';
import { marketsPicker } from './edit-master-item/markets-picker.directive';
import { MoveKeysController } from './move-keys/move-keys.view';

angular.module('master', [
   'stateService',
   'dataService',
   'dialogFactory',
   'apiService',
   'publish',
   'components'
])
   .controller('masterViewController', MasterViewController)
   .directive('masterMenu', masterMenuDirective)
   .controller('EditMasterLabelCtrl', EditMasterLabelController)
   .controller('EditMasterItemCtrl', EditMasterItemController)
   .directive('addOption', AddOptionDirective)
   .controller('EditApplicationCtrl', EditApplicationController)
   .controller('CompareRevisionsCtrl', CompareRevisionsCtrl)
   .factory('diffingUtil', diffingUtilFactory)
   .directive('revisionList', revisionListDirective)
   .controller('AddMasterSectionCtrl', AddMasterSectionController)
   .controller('ImportDialogCtrl', ImportDialogCtrl)
   .directive('importList', importListDirective)
   .factory('masterDataMappingUtil', masterDataMappingUtilFactory)
   .directive('marketsPicker', marketsPicker)
   .controller('MoveKeysCtrl', MoveKeysController);

export const view = {
   controller: 'masterViewController',
   controllerAs: 'vm',
   template
};

import template from './select-application.template.html';

export function selectApplicationDirective($timeout, dataService, stateService, sessionService, analyticsService) {

   return {
      restrict: 'E',
      template: template,
      link: function(scope, element, attrs) {
         const user = sessionService.getUser();
         const states = stateService.getStates();
         const data = dataService.getData();
         const hideNotTranslatable = attrs.hasOwnProperty('hideNotTranslatable');

         scope.onSelectApplication = onSelectApplication;
         scope.isEmpty = false;

         scope.$on('APPLICATIONS_UPDATED', setApplications);

         dataService.getApplications().then(function() {
            setApplications();
            if (!states.application || !data.applications[states.application]) {
               if (scope.applications.length) {
                  stateService.setState('application', scope.applications[0].id);
               }
            }
         });

         function onSelectApplication(app) {
            const newApp = (app || states.application);
            stateService.setState('application', newApp, true); 
            
            const category = states.path === '/translate' ? 'translate' : 'master';
            analyticsService.trackEvent(category, 'change_application', newApp);
         }

         function setApplications() {
            scope.applications = [];
            for (const app in data.applications) {
               if (!user.applications.find(item => item.id === app)) {
                  continue;
               } 
               if (isVisibleForUser(app)) {
                  scope.applications.push({
                     id: app,
                     name: data.applications[app].displayName
                  });
               }
            }
            if (!scope.applications.length) {
               scope.isEmpty = true;
            }
         }

         function isVisibleForUser(app) {
            return (hideNotTranslatable ? data.applications[app].isTranslatable : true) && user.can('read', 'application')/* && user.hasAccessToApp(app) */;
         }
      }
   };

}

selectApplicationDirective.$inject = ['$timeout', 'dataService', 'stateService', 'sessionService', 'analyticsService'];

export function masterDataMappingUtilFactory() {
   return {
      mapAllTablesLabelsOnGroup,
      mapTableOnSections,
      sortItemsAlphabetically,
   };

   function createSortFunction(prop) {
      return (a, b) => {
         if (a[prop] < b[prop]) {
            return -1;
         }
         if (a[prop] > b[prop]) {
            return 1;
         }
         return 0;
      };
   }

   function mapTableOnSections(table) {

      const sectionsArray = [];

      for (const section in table) {
         const items = [];

         for (const item in table[section]) {
            table[section][item].key = item;
            items.push(table[section][item]);
         }

         const onLabelGroups = items.reduce((accumulated, currentItem) => {
            const accGroup = accumulated.find(group => group.label === currentItem.labelGroup);

            if (accumulated && accGroup) {
               accGroup.items.push(currentItem);
            } else {
               accumulated.push({ label: currentItem.labelGroup, items: [currentItem] });
            }

            return accumulated;
         }, []);

         if (items.length > 0) {
            sectionsArray.push({ sectionName: section, labelGroups: onLabelGroups });
         }
      }
      return sectionsArray;
   }

   function mapAllTablesLabelsOnGroup(tables) {
      const tablesClone = Object.assign({}, tables);

      for (const prop in tablesClone) {
         tablesClone[prop] = mapOneTablesLabelsOnGroup(tablesClone[prop]);
      }

      return tablesClone;
   }

   function mapOneTablesLabelsOnGroup(table) {
      const tableOnGroups = getTableOnLabelGroups(table);
      const tableOnGroupsItemsSortedAlpha = sortItemsAlphabetically(tableOnGroups);
      const tableOnGroupsLabelsSortedAlhpha = sortLabelsAlphabetically(tableOnGroupsItemsSortedAlpha);
      return tableOnGroupsLabelsSortedAlhpha;
   }

   function sortItemsAlphabetically(table) {
      return table.map((labelGroup) => {
         labelGroup.items = labelGroup.items.sort(createSortFunction('key'));
         return labelGroup;
      });
   }

   function getTableOnLabelGroups(table) {
      let labelGroups = [];

      for (const prop in table) {
         // Adds key to every item.
         table[prop].key = prop;

         // Create array of groups and add belonging list-items to every group.
         const doesGroupExist = labelGroups.some(labelGroup => table[prop].labelGroup === labelGroup.label);

         if (doesGroupExist) {
            labelGroups = labelGroups.map((labelGroup) => {
               if (labelGroup.items && labelGroup.label === table[prop].labelGroup) {
                  labelGroup.items.push(table[prop]);
               }
               return labelGroup;
            });
         } else {
            labelGroups.push({
               label: table[prop].labelGroup,
               items: [table[prop]]
            });
         }
      }

      return labelGroups;
   }

   // function masterDataMappingUtil(table) {
   //    return table.map((labelGroup) => {
   //       labelGroup.items = labelGroup.items.sort(createSortFunction('key'));
   //       return labelGroup;
   //    });
   // }

   function sortLabelsAlphabetically(table) {
      return table.sort(createSortFunction('label'));
   }
}

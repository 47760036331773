export default function ToastersFactory() {
   const factory = {
      show: () => {
         throw new Error('Missing show function');
      }
   };
   
   factory.connect = (handler) => {
      factory.show = handler;
   };

   return factory;
}
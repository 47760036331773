import angular from 'angular';
import _ from 'lodash';
import {view as localValueChange} from './local-value-change/local-value-change.view';
import './translate.less';
import '../core/services/dataService.js';
import '../core/services/stateService.js';
import '../core/services/dialog.factory';
import TranslateUtils from '../core/utils/TranslateUtils';
import FileUtils from '../core/utils/FileUtils';

export function TranslateController($scope, $window, $timeout, dataService, stateService, dialogFactory, filterService, sessionService, toastersFactory, analyticsService) {

   const vm = this;
   let selectedElement = null;

   vm.states = stateService.getStates();
   vm.data = dataService.getData();
   vm.hasChanged = false;
   vm.onSelectLocale = () => {
      onSelectLocale();
      analyticsService.trackEvent('translate', 'change_market', vm.states.locale);
   };
   vm.onSelectRevision = onSelectRevision;
   vm.onSelectLabel = onSelectLabel;
   vm.onSelectKey = onSelectKey;
   vm.onUnselectKey = onUnselectKey;
   vm.compareToMaster = compareToMaster;
   vm.onReferenceChanged = onReferenceChanged;
   vm.onReferenceMarketChanged = onReferenceMarketChanged;
   vm.onClickCopyFromReference = copyFromReference;
   vm.onItemValueChanged = onItemValueChanged;
   vm.onItemFocus = onItemFocus;
   vm.onItemBlur = onItemBlur;
   vm.onToggleChanged = onToggleChanged;
   vm.onCloseNotification = onCloseNotification;
   vm.onLocalChangeClick = onLocalChangeClick;
   vm.isMasterUpdated = dataService.isMasterUpdated;
   vm.searchText = null;
   vm.selectedKey = null;
   vm.selectedLabel = null;
   vm.showNotification = true;
   vm.isLoading = false;
   vm.noData = false;
   vm.showOnlyDiffs = false;
   vm.filteredResults = {};
   vm.hiddenTabs = [];
   vm.tabs = [];
   vm.tabSelected = 0;
   vm.onChangeFilter = onChangeFilter;
   vm.onResetFilters = onResetFilters;
   vm.filterMatchesForGroup = filterMatchesForGroup;
   vm.user = sessionService.getUser();
   vm.isSaving = false;

   vm.getTextareaRows = (reference, section, key) => {
      let value = String(vm.data[reference] && vm.data[reference][section] && vm.data[reference][section][key] && vm.data[reference][section][key].value || '');
      let rows = value.length > 56 ? 3 : 1;
      if (vm.selectedKey === key) {
         while (value.endsWith('\n')) {
            rows += 1;
            value = value.slice(0, -1);
         }
      }
      return rows;
   };

   vm.onClearValueClick = (sectionKey, key) => {
      dialogFactory.confirm({
         header: 'Clear value',
         body: 'Clear the current value and inherit value from Master Copy?',
         proceedText: 'Clear value',
         proceedCallback: () => {
            updatePayload('removed', {
               section: sectionKey,
               label: key,
               locale: vm.states.locale
            });
            vm.data.local[sectionKey][key].changed = true;
            vm.data.local[sectionKey][key].value = null;

            analyticsService.trackEvent('translate', 'clear_value');
         }
      });
   };

   $scope.$watch('vm.filters.searchString', _.debounce((search) => {
      if (search == null || search === '') {
         return;
      }

      analyticsService.trackEvent('translate', 'search', search);
   }, 1000));

   function onChangeFilter(filter, store) {
      filterService.onChangeFilter(filter, store);

      // This is called when searching as well (with value 'searchString')
      if (filter !== 'searchString') {
         analyticsService.trackEvent('translate', 'change_filter', filter);
      }
   }

   function onResetFilters() {
      filterService.onResetFilters();
   }

   function filterMatchesForGroup(tab) {
      return vm.filteredResults[tab];
   }

   vm.isFilterOrSearchActive = () => {
      return vm.filters.searchString || vm.states.newOrUpdated;
   };

   $scope.$watch('vm.data.masterGroups', (masterGroups) => {
      vm.tabs = [];

      for (const key in masterGroups) {
         vm.tabs.push({
            key,
            title: key,
            listLength: masterGroups[key].reduce((acc, curr) => acc + curr.items.length, 0),
            bubblePrimary: vm.data.masterSectionCount[key].unset,
            bubbleSecondary: vm.data.masterSectionCount[key].updated,
            labels: masterGroups[key]
         });
      }
   });

   vm.diffButtonToggled = () => {
      vm.showOnlyDiffs = vm.showOnlyDiffs ? false : true;

      analyticsService.trackEvent('translate', 'show_reference_differences');
   };

   function doesItemDiff(data, reference, section, key) {
      if (data && reference && section && key) {
         const mainValue = data.local && data.local[section] && data.local[section][key] && data.local[section][key].value ? data.local[section][key].value : undefined;
         const refValue = data[reference] && data[reference][section] && data[reference][section][key] && data[reference][section][key].value ? data[reference][section][key].value : undefined;
         return refValue !== mainValue;
      }
      return false;
   }

   vm.isFilterActive = () => {
      return filterService.isAnyFilterActive(vm.filters, 'translate');
   };

   vm.getLabelItemClass = function(section, key) {
      if (vm.data.master && vm.data.local) {
         return {
            selected: vm.selectedKey === key,
            unset: vm.data.local && (!vm.data.local[section] || !vm.data.local[section][key]),
            updated: vm.isMasterUpdated(section, key) && vm.data.local[section] && vm.data.local[section][key] && !vm.data.local[section][key].changed,
            multiline: vm.data.local[section] && vm.data.local[section][key] && vm.data.local[section][key].value && vm.data.local[section][key].value.length >= 56,
            changed: vm.data.local[section] && vm.data.local[section][key] && vm.data.local[section][key].changed,
            diffing: doesItemDiff(vm.data, vm.states.reference, section, key) && vm.showDiffFeature
         };
      } else {
         return {};
      }
   };

   $scope.$watch('vm.states.application', init);

   function init() {
      resetPayload();
      vm.isLoading = true;
      vm.filters = filterService.getFilters();
      stateService.setState('hasChanges', false);
      document.body.addEventListener('click', onUnselectKey);
      $window.addEventListener('fileImported', e => $timeout(() => vm.isLoading = e.detail.isLoading));
      if (vm.states.application/* && vm.user.hasAccessToApp(vm.states.application) */) {
         const application = vm.data.applications[vm.states.application];

         if (application == null || (application && !application.isTranslatable)) {
            stateService.setState('application', null);
         } else {
            const shouldExcludeUnapproved = application ? application.showOnlyApproved : false;
            const excludeUnapproved = shouldExcludeUnapproved && !vm.user.can('read_full', 'translation');
            dataService.getMaster(null, excludeUnapproved).then(onSelectLocale);
         }
      } else {
         vm.isLoading = false;
         vm.noData = true;
      }
   }

   function onSelectLocale() {
      resetPayload();
      vm.noData = false;

      if (
         vm.states.locale &&
         vm.user.hasAccessToLocale(vm.states.locale) &&
         vm.states.application /* &&
         vm.user.hasAccessToApp(vm.states.application) */
      ) {
         stateService.setState('locale', vm.states.locale, true);
         dataService.getRevisions('local', vm.states.locale);
         compareToMaster();
         dataService.getLatest()
            .then(function() {
               dataService.updateMasterGroups();
               vm.isLoading = false;
            }, function(xhr) {
               if (xhr.status === 404) {
                  dialogFactory.alert({
                     header: 'Translation not found',
                     body: 'There is no translation yet for this market'
                  });
               }
            });
         stateService.setState('hasChanges', false);
      } else {
         // dataService.updateMasterGroups();
         vm.isLoading = false;
         vm.noData = true;
      }
   }

   function onSelectRevision() {
      vm.showOnlyDiffs = false;
      if (vm.states.localRevision) {
         stateService.setState('reference', 'revision');
         getLocalRevision();
      }
   }

   function getLocalRevision() {
      dataService.getRevision(vm.states.reference, vm.states.locale, vm.states.localRevision)
         .catch(function() {
            dialogFactory.alert({
               header: 'Translation not found',
               body: 'The selected revision could not be loaded'
            });
         });
   }

   function onReferenceChanged() {
      vm.showOnlyDiffs = false;
      vm.showDiffFeature =
         vm.states.reference === 'revision' ||
         vm.states.reference === 'market';

      if (vm.states.reference === 'revision') {
         if (vm.data.localRevisions.length) {
            stateService.setState('localRevision', vm.data.localRevisions[0].value, false);
            getLocalRevision();
         } else {
            dataService.clearRevision(vm.states.reference);
         }
      } else if (vm.states.reference === 'market' && vm.states.referenceMarket) {
         dataService.getRevisions('market', vm.states.referenceMarket, true);
      }

      if (vm.states.reference !== 'master') {
         analyticsService.trackEvent('translate', 'change_reference', vm.states.reference);
      }
   }

   function onReferenceMarketChanged() {
      vm.showOnlyDiffs = false;
      dataService.getRevisions('market', vm.states.referenceMarket, true);
   }

   function compareToMaster() {
      stateService.setState('reference', 'master', true);
      resetToLatestRevision();
   }

   function resetToLatestRevision() {
      stateService.setState('localRevision', null, false);
   }

   function copyFromReference(section, key, event) {
      event.stopPropagation();

      if (section && key) {
         copyKeyValueFromReference(section, key);
         analyticsService.trackEvent('translate', 'copy_value', 'single');
      } else if (section) {
         dialogFactory.confirm({
            header: 'Copy All Keys',
            body: 'Are you sure you want to copy all key values currently visible in the section?',
            proceedText: 'Copy All',
            proceedCallback: () => {
               for (key in vm.data[vm.states.reference][section]) {
                  if (vm.data[vm.states.reference][section].hasOwnProperty(key)) {
                     if (keyExistsInMasterGroups(section, key)) {
                        copyKeyValueFromReference(section, key);
                     }
                  }
               }
               analyticsService.trackEvent('translate', 'copy_value', 'all');
            }
         });
      }

      stateService.setState('hasChanges', true);
      console.log('data ', angular.copy(vm.data));
   }

   function keyExistsInMasterGroups(section, key) {
      for (const tag of vm.data.masterGroups[section]) {
         for (const item of tag.items) {
            if (item.key === key) {
               return true;
            }
         }
      }

      return false;
   }

   function copyKeyValueFromReference(section, key) {
      vm.data.local[section] = vm.data.local[section] || {};
      vm.data.local[section][key] = vm.data.local[section][key] || {};
      vm.data.local[section][key].value = vm.data[vm.states.reference][section][key].value;

      onItemValueChanged (section, key);
   }

   this.payload = {
      new: [],
      updated: [],
      removed: []
   };

   const resetPayload = () => {
      this.payload = {
         updated: [],
         removed: []
      };
   };

   const updatePayload = (type, item) => {
      const existsInUpdated = this.payload.updated.find(i => i.section === item.section && i.label === item.label);
      const filterOutItem = (arr, item) => arr.filter(i => i.section !== item.section || i.label !== item.label);

      const removeEmptyStrings = vm.data.applications[vm.states.application].removeEmptyStrings;
      const master = vm.data.master[item.section][item.label];
      const local = vm.data.local[item.section][item.label];
      const allowed = !(removeEmptyStrings && !!master && !master.allowEmpty && local.value === '');

      switch (type) {
         case 'updated': {
            if (!existsInUpdated && allowed) {
               this.payload.updated = [...this.payload.updated, item];
               this.payload.removed = filterOutItem(this.payload.removed, item);
            }
            break;
         }
         case 'removed': {
            if (!existsInUpdated) {
               this.payload.removed = [...this.payload.removed, item];
            } else {
               this.payload.updated = filterOutItem(this.payload.updated, item);
               this.payload.removed = [...this.payload.removed, item];
            }
            break;
         }
      }

      const hasChanges = !!['updated', 'removed'].find(key => this.payload[key].length);

      console.log(this.payload);

      stateService.setState('hasChanges', hasChanges);
   };

   function onItemValueChanged (section, label) {
      const item = {
         section,
         label
      };

      const original = vm.data.$local && vm.data.$local[section] && vm.data.$local[section][label] && vm.data.$local[section][label].value || null;
      const changed = vm.data.local && vm.data.local[section] && vm.data.local[section][label] && vm.data.local[section][label].value || null;

      vm.data.local[section][label].changed = original !== changed;

      updatePayload('updated', item);
   }

   function onItemFocus(obj) {
      vm.focusedInputObj = obj;
   }

   function onItemBlur(section, key) {
      // if (vm.data.applications[vm.states.application].removeEmptyString
      //    && vm.data.local[section][key]
      //    && vm.data.local[section][key].value === ''
      //    && vm.data.master[section][key]
      //    && vm.data.master[section][key].allowEmpty === false) {
      //    delete vm.data.local[section][key];
      // } else {
      let value = vm.data.local[section] && vm.data.local[section][key] && vm.data.local[section][key].value;

      if (value && typeof value === 'string') {
         while (value.endsWith('\n')) {
            value = value.slice(0, -1);
         }
         vm.data.local[section][key].value = value;
      }

      const oldValue = vm.data.$local[section] && vm.data.$local[section][key] && vm.data.$local[section][key].value;

      if (value !== oldValue) {
         analyticsService.trackEvent('translate', 'edit_key_value', 'inline');
      }
      // }

      vm.focusedInputObj = undefined;
   }

   function onToggleChanged(section, label, event) {
      event.stopPropagation();
      vm.data.local[section][label].changed = !vm.data.local[section][label].changed;
      updatePayload('updated', {
         section,
         label
      });
   }

   function onCloseNotification() {
      vm.enableNotification = false;
   }

   function onLocalChangeClick(section, key) {
      const left = vm.data.$local && vm.data.$local[section] && vm.data.$local[section][key] ? vm.data.$local[section][key].value : '';
      const right = vm.data.local && vm.data.local[section] && vm.data.local[section][key] ? vm.data.local[section][key].value : '';

      dialogFactory.custom(Object.assign({
         header: 'Local Value Change',
         data: {
            left: left,
            right: right
         }
      }, localValueChange));
   }

   function onSelectLabel(labelGroup) {
      labelGroup.expanded = !labelGroup.expanded;

      analyticsService.trackEvent('translate', 'toggle_key_group', labelGroup.expanded ? 'open' : 'close');
   }

   function onSelectKey(key, label, event) {
      event.stopPropagation();

      if (vm.selectedKey !== key) {
         restoreTextareaSize(event.currentTarget);
      }

      vm.selectedKey = key;
      vm.selectedLabel = label;
   }

   function restoreTextareaSize(element = null) {
      if (selectedElement !== null) {
         const textarea = selectedElement.querySelector('.item-local-value textarea');
         if (textarea) {
            textarea.style.height = '';
         }
      }

      selectedElement = element;
   }

   function onUnselectKey() {
      vm.selectedKey = null;
      restoreTextareaSize();
      $timeout(function() {
         $scope.$apply();
      }, 0);
   }

   vm.onClickSave = () => {
      this.payload.updated = this.payload.updated
         .filter(item => {
            const removeEmptyStrings = vm.data.applications[vm.states.application].removeEmptyStrings;
            const master = vm.data.master[item.section][item.label];
            const local = vm.data.local[item.section][item.label];
            return !(removeEmptyStrings && !!master && !master.allowEmpty && local.value === '');
         })
         .map(item => {
            return Object.assign({}, item, vm.data.local[item.section][item.label]);
         });

      vm.isSaving = true;
      dataService.saveLocalization(this.payload).then(() => {
         resetPayload();
         stateService.setState('hasChanges', false);
         dataService.getRevisions('local', vm.states.locale);
         dataService.updateMasterGroups();
         vm.isSaving = false;
      }).catch(()=>{
         vm.isSaving = false;
      });
   };

   vm.onSelectImportAs = async (extension) => {
      try {
         const data = await FileUtils.importTranslation(vm.states.application, vm.states.locale, extension);
         console.log(data);
         const changed = TranslateUtils.merge(vm.data.master, vm.data.local, data);

         Object.entries(changed).forEach(([section, labels]) => {
            Object.entries(labels).forEach(([label, item]) => {
               vm.data.local[section] = vm.data.local[section] || {};
               vm.data.local[section][label] = vm.data.local[section][label] || {};
               vm.data.local[section][label].value = item.value;

               onItemValueChanged(section, label);
            });
         });

         analyticsService.trackEvent('translate', 'import', extension);

         $scope.$apply();
      } catch (err) {
         console.log(err);
         toastersFactory.show({
            layout: 'failure',
            body: 'Error importing. ' + err.message,
            closeable: true
         });
         $scope.$apply();
      }
   };

   vm.onSelectExportAs = async (extension) => {
      if (vm.states.hasChanges) {
         return dialogFactory.alert({
            header: 'Unsaved changes',
            body: `There are unsaved changes, please save your changes before an export`
         });
      }

      const filename = `${vm.states.application}_${vm.states.locale}_${vm.states.localRevision}`;
      FileUtils.exportTranslation(
         extension,
         vm.states.application,
         vm.states.locale,
         filename,
         vm.data.master,
         vm.data.$local
      );

      analyticsService.trackEvent('translate', 'export', extension);
   };
}

TranslateController.$inject = [
   '$scope',
   '$window',
   '$timeout',
   'dataService',
   'stateService',
   'dialogFactory',
   'filterService',
   'sessionService',
   'toastersFactory',
   'analyticsService',
];

import angular from 'angular';
import template from './publish.template.html';

import { PublishController } from './publish.controller';
import { UnpublishedChangesController } from './unpublished-changes/unpublished-changes.view';

angular.module('publish', ['stateService', 'apiService', 'dataService', 'dialogFactory'])
   .controller('PublishCtrl', PublishController)
   .controller('UnpublishedChangesCtrl', UnpublishedChangesController);

export const view = {
   template
};


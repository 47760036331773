import importListTemplate from './import-list.template.html';
import './import-list.less';

export function importListDirective() {
   return {
      retrict: 'E',
      template: importListTemplate,
      scope: {
         items: '<',
         hideCurrentValue: '<',
         onKeySelect: '&',
         totalKeysAmount: '<',
         emptyMessage: '@',
         highlightRows: '<'
      },
      link: (scope) => {
         scope.selected = [];

         scope.$watch('selected', () => {
            scope.onKeySelect({ keys: scope.selected });
         }, true);

         scope.totalKeysAmount = scope.totalKeysAmount || scope.items.length;

         scope.keysWithLabelGroups = scope.items.reduce((obj, item) => {
            if (obj[item.section]) {
               if (obj[item.section][item.labelGroup]) {
                  obj[item.section][item.labelGroup].push(item);
               } else {
                  obj[item.section][item.labelGroup] = [item];
               }
            } else {
               obj[item.section] = {
                  [item.labelGroup]: [item]
               };
            }

            return obj;
         }, {});

         scope.isSelected = (section, labelGroup, label) => scope.selected.find(selected => 
            selected.section === section &&
            selected.labelGroup === labelGroup &&
            selected.label === label);

         scope.onItemClicked = (section, labelGroup, label, checked) => {
            if (checked === true) {
               scope.selected.push({ label, labelGroup, section });
            } else {
               scope.selected = scope.selected.filter(item =>
                  item.label !== label ||
                  item.labelGroup !== labelGroup ||
                  item.section !== section
               );
            }
         };
         
         scope.isLabelGroupSelected = (section, labelGroup) => {
            return scope.items
               .filter(item => item.section === section && item.labelGroup === labelGroup)
               .every(item => scope.isSelected(section, labelGroup, item.label));
         };

         scope.onLabelGroupClicked = (section, labelGroup, checked) => {
            scope.items
               .filter(item => item.section === section && item.labelGroup === labelGroup)
               .forEach(item => scope.onItemClicked(section, labelGroup, item.label, checked));
         };

         scope.isAllKeysSelected = () => {
            return scope.items.every(item => scope.isSelected(item.section, item.labelGroup, item.label));
         };

         scope.onAllKeysClicked = (checked) => {
            scope.selected = checked === true ? scope.items.map(item => ({
               section: item.section,
               labelGroup: item.labelGroup,
               label: item.label,
               markets: item.markets
            })) : [];
         };
      }
   };
}

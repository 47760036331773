import 'core/services/sessionService';
import '../core/services/dialog.factory';
import './admin.less';
import {view as MoveKey} from './move-key/move-key.view';

export function AdminController(dialogFactory, sessionService) {

   const vm = this;
   const operations = {
      move_key: MoveKey
   };

   vm.init = init;
   vm.onSelectOperation = onSelectOperation;
   vm.isLoading = false;

   function init() {
      vm.isLoading = true;
      vm.user = sessionService.getUser();
      if (!vm.user.isAdmin) {
         dialogFactory.alert({
            header: 'Forbidden',
            body: 'You do not have permissions to execute operations'
         });
      }
   }

   function onSelectOperation(operation) {
      if (operations[operation]) {
         dialogFactory.custom({
            header: operations[operation].header,
            template: operations[operation].template
         });
      } else {
         dialogFactory.alert({
            header: 'Forbidden',
            body: 'Operation ' + operation + ' is not supported'
         });
      }
   }
}

AdminController.$inject = [
   'dialogFactory',
   'sessionService'
];

import template from './icon-popup.template.html';
import './icon-popup.less';

export function IconPopupDirective() {
   return {
      restrict: 'E',
      template,
      scope: {
         icon: '@',
         title: '@',
         body: '@'
      },
      link: function(scope, element) {
         scope.active = false;
         scope.iconClasses = `fa fa-${scope.icon}`;
         element.removeAttr('title');
      }
   };
}

import angular from 'angular';
import '../../services/dialog.factory';
import dialog from './dialog.directive';
import { DialogsContainerDirective } from './dialogs-container.directive.js';

angular.module('dialogs', ['dialogFactory'], ['$compileProvider', moduleConfig])
   .directive('dialogs', DialogsContainerDirective)
   .directive('modal', dialog);

function moduleConfig($compileProvider) {
   $compileProvider.directive('compile', ['$compile', function($compile) {
      return function(scope, element, attrs) {
         scope.$watch(
            function(scope) {
               return scope.$eval(attrs.compile);
            },
            function(value) {
               element.html(value);
               $compile(element.contents())(scope);
            }
         );
      };
   }]);
}
/**
 * Merges master and locale data from state, to produce an object
 * that includes all keys, but has value null for any keys not
 * translated.
 * 
 * @param {object} master Master object 
 * @param {object} local Local object
 * @param {object} updated Updated object
 * @returns Merged object
 */
function merge(master, local, updated) {
   const merged = {};

   Object.entries(master).forEach(([section, labels]) => {
      Object.entries(labels).forEach(([label, item]) => {
         const updatedValue = updated[section] && updated[section][label] && updated[section][label].value;
         const localValue = local[section] && local[section][label] && local[section][label].value;

         if (updatedValue != null && updatedValue != localValue) {
            merged[section] = merged[section] || {};

            merged[section][label] = {
               application: item.application,
               section,
               label,
               value: updatedValue
            };
         }
      });
   });

   return merged;
}

export default {
   merge
};

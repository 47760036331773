import template from './move-keys.template.html';
// import '../../core/services/dialog.factory';
import './move-keys.less';
// import './import-list/import-list.directive';

export const view = {
   template
};

export function MoveKeysController() {
   const vm = this;

   vm.selectedLabelGroup = null;

   vm.init = (dialog) => {
      vm.dialog = dialog;
      vm.selectedList = dialog.data.selectedList;
      vm.labelGroups = dialog.data.labelGroups;
   };

   vm.onClose = () => {
      vm.dialog.cancelCallback();
      vm.dialog.close();
   };

   vm.onMove = () => {
      vm.dialog.proceedCallback(vm.selectedLabelGroup);
      vm.dialog.close();
   };
}
import template from './toasters.template.html';
import './toasters.less';
import Toaster from '@ikea-aoa/ikea-component-toaster';

export function ToastersDirective(toastersFactory) {
   return {
      restrict: 'E',
      template,
      controller: ['$scope', function ($scope) {
         $scope.queue = [];
         $scope.visible = false;
         $scope.body = null;
         $scope.layout = 'default';
         $scope.closeable = false;
         $scope.duration = 2000;
         $scope.events = Toaster.events({
            closed: () => {
               $scope.visible = false;
               $scope.body = '';
               $scope.queue = $scope.queue.slice(1);
               $scope.$apply();
            }
         });

         $scope.$watch('queue', function (a) {
            if (!$scope.queue.length || $scope.visible) {
               return;
            }
            
            show($scope.queue[0]);
         }, true);

         function show({ layout, body, duration, closeable }) {
            setTimeout(() => {
               $scope.visible = true;
               $scope.body = body;
               $scope.layout = layout;
               $scope.closeable = closeable;
               $scope.duration = duration;
               $scope.$apply();
            }, 10);
         }

         toastersFactory.connect(({ layout = 'default', body, duration = (Math.random() * 1000) + 3000, closeable = false }) => {
            $scope.queue.push({ layout, body, duration, closeable });
         });
      }],
   };
}

ToastersDirective.$inject = ['toastersFactory'];

import 'core/services/dataService.js';
import 'core/services/stateService.js';
import '../../core/services/dialog.factory';
import './add-master-section.less';
import template from './add-master-section.template.html';

export const view = {
   template: template
};

export function AddMasterSectionController($scope, dataService, stateService) {

   const vm = this;

   vm.states = stateService.getStates();
   vm.data = dataService.getData();
   vm.hasChanged = false;

   vm.init = init;
   vm.onLabelChanged = onLabelChanged;
   vm.onConfirm = confirmAndClose;
   vm.onCancel = close;
   vm.label = null;

   function init(dialog) {
      vm.dialog = dialog;
      vm.hasChanged = false;
   }

   function onLabelChanged() {
      vm.hasChanged = true;
   }

   function close() {
      vm.dialog.close();
   }

   function confirmAndClose() {
      vm.dialog.proceedCallback({label: vm.label});
      vm.dialog.close();
   }
}

AddMasterSectionController.$inject = ['$scope', 'dataService', 'stateService'];

import angular from 'angular';

import '../services/stateService.js';
import '../services/routeService.js';
import '../services/sessionService.js';
import '../services/dialog.factory.js';
import './dialogs-container/dialogs.module';

import tabMenu from './tab-menu/tab-menu.directive';
import highlightButton from './highlight-button/highlight-button.directive';
import genericButton from './generic-button/generic-button.directive';
import { selectButtonDirective } from './select-button/select-button.directive';
import { BubbleDirective } from './bubble/bubble.directive';
import { fullscreenSpinnerComponent } from './fullscreen-spinner/fullscreen-spinner.component';
import { selectApplicationDirective } from './select-application/select-application.directive';
import { CharLengthDirective } from './char-length/char-length.directive';
import { ConfirmLeaveDirective } from './confirm-leave/confirm-leave.directive';
import { TabViewDirective } from './tab-view/tab-view.directive.js';
import { LoginFormDirective } from './login-form/login-form.directive.js';
import { LoginFormController } from './login-form/login-form.controller.js';
import { CheckboxListDirective } from './checkbox-list/checkbox-list.directive.js';
import { RadioListDirective } from './radio-list/radio-list.directive.js';
import { newDomainModalFactory } from './modal-new-domain/create-new-domain-modal.factory.js';
import { NewDomainModalController } from './modal-new-domain/modal/new-domain-modal.module.js';
import { IconPopupDirective } from './icon-popup/icon-popup.directive.js';
import { ToastersDirective } from './toasters/toasters.directive';
import ToastersFactory from './toasters/toasters.factory';

angular.module('components', ['dataService', 'sessionService', 'stateService', 'filterService', 'dialogFactory', 'routeService', 'dialogs'])
   .directive('tabMenu', tabMenu)
   .directive('highlightButton', highlightButton)
   .directive('genericButton', genericButton)
   .directive('selectButton', selectButtonDirective)
   .directive('bubble', BubbleDirective)
   .component('fullscreenSpinner', fullscreenSpinnerComponent)
   .directive('selectApplication', selectApplicationDirective)
   .directive('charLength', CharLengthDirective)
   .directive('confirmLeave', ConfirmLeaveDirective)
   .directive('tabView', TabViewDirective)
   .directive('loginForm', LoginFormDirective)
   .controller('loginForm', LoginFormController)
   .directive('checkboxList', CheckboxListDirective)
   .directive('radioList', RadioListDirective)
   .directive('ngIndeterminate', function() {
      return {
         restrict: 'A',
         link: function(scope, element, attributes) {
            scope.$watch(attributes.ngIndeterminate, function (value) {
               element.prop('indeterminate', !!value);
            });
         }
      };
   })
   .factory('newDomainModal', newDomainModalFactory)
   .controller('NewDomainModalController', NewDomainModalController)
   .directive('iconPopup', IconPopupDirective)
   .factory('toastersFactory', ToastersFactory)
   .directive('toasters', ToastersDirective);

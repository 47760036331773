const deployTargets = [
   {
      id: 'kp-aws-dev',
      label: 'Kitchen Planner AWS (dev)',
      environments: ['stage']
   },
   {
      id: 'kp-aws-training',
      label: 'Kitchen Planner AWS (training)',
      environments: ['prod', 'dev']
   },
   {
      id: 'kp-aws-stage',
      label: 'Kitchen Planner AWS (stage)',
      environments: ['prod']
   },
   {
      id: 'kp-aws-verification',
      label: 'Kitchen Planner AWS (verification)',
      environments: ['prod']
   },
   {
      id: 'kp-aws-prod',
      label: 'Kitchen Planner AWS (prod)',
      environments: ['prod']
   },
   {
      // this one will be deprecated and replaced by "kp-aws-prod" when verified by NKP team
      id: 'kp-aws',
      label: 'Kitchen Planner AWS',
      environments: ['prod']
   },
   {
      id: 'verification',
      label: 'Verification (CTE/PPE)',
      environments: ['prod', 'stage', 'dev']
   },
   {
      id: 'prod',
      label: 'Production',
      environments: ['prod']
   },
   {
      id: 'cybercom-aws',
      label: 'Cybercom AWS',
      environments: ['prod', 'dev']
   },
   {
      id: 'test',
      label: 'Test',
      environments: ['dev']
   },
   {
      id: 'cybercom-aws-test',
      label: 'Cybercom AWS TEST',
      environments: ['dev']
   }
];

export default deployTargets;

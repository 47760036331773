import template from './new-domain-modal.template.html';
import './new-domain-modal.less';

export const view = {
   template
};

export function NewDomainModalController() {
   const vm = this;
   vm.init = init;
   function init(dialog) {
      vm.environment = dialog.data.environment;
   }
}

import './login-form.less';
import template from './login-form.template.html';

export function LoginFormDirective() {
   return {
      restrict: 'E',
      controller: 'loginForm',
      controllerAs: 'login',
      template: template,
      link: function (scope, el, attr) {
         console.log('login form directive ', attr);

         if (attr.isDialog === 'true') {
            scope.login.isDialog = true;
         }
      }
   };
}

export function LoginFormController(routeService, sessionService, stateService, dialogFactory) {
   const vm = this;
   vm.isDialog = false;

   vm.init = init;
   vm.login = login;

   function init() {
      vm.states = stateService.getStates();
   }

   function login(event) {
      event.preventDefault();

      vm.loginFailCountReached = false;
      if (stateService.getState('loginFailCount') >= 3) {
         vm.loginFailCountReached = true;
      }

      sessionService.login(vm.username, vm.password)
         .then(function() {
            redirectToView();
         },function(xhr) {
            vm.loginError = xhr.status;
         });
   }

   function redirectToView() {
      if (vm.isDialog) {
         const dialog = dialogFactory.getCurrentDialog();
         console.log('dialog', dialog);
         if (dialog.data.reloadOnSuccess) {
            window.location.reload();
         } else {
            dialog.close();
         }
      } else {
         const view = stateService.getState('redirect');
         routeService.setPath(view && view !== '/auth' ? view : '/translate');
         stateService.clearState('redirect');
      }
   }
}

LoginFormController.$inject = [
   'routeService',
   'sessionService',
   'stateService',
   'dialogFactory'
];

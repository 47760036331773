/*
 * CustomEvent is not supported in Internet Explorer
 */
(function () {
   if (typeof window.CustomEvent === 'function') {
      return false;
   }
   function CustomEvent(event, params) {
      const evt = document.createEvent('CustomEvent');
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
   }
   CustomEvent.prototype = window.Event.prototype;
   window.CustomEvent = CustomEvent;
   console.log('polyfill for CustomEvent was added');
})();
export function ConfirmLeaveDirective(stateService) {
   return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attr, ctrl) {
         const states = stateService.getStates();
         const unsavedChangesMessage = 'Do you want to leave this page? \n\nChanges you made will not be saved.';

         element.on('change', function() {
            const val = ctrl.$modelValue;

            if (!states.hasChanges || confirm(unsavedChangesMessage)) {
               states.hasChanges = false;
               ctrl.$setViewValue(element.val().replace('string:', ''), 'confirmed');
               ctrl.$$debounceViewValueCommit('confirmed');
            } else if (element.val().includes(':')) {
               element.val(element.val().split(':')[0] + ':' + val);
            } else {
               element.val(val);
            }
         });
      }
   };
}

ConfirmLeaveDirective.$inject = ['stateService'];

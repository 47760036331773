import revisionListTemplate from './revision-list.template.html';
import './revision-list.less';

export function revisionListDirective() {
   return {
      retrict: 'E',
      template: revisionListTemplate,
      scope: {
         commonTable: '<',
         showOnlyDiff: '<'
      },
      link: (scope) => {
         scope.doesGroupHaveDiffingItems = (labelGroup) => {
            return labelGroup.items.some(item =>
               item.isDiffing ||
               (item.primaryTable && !item.secondaryTable) ||
               (!item.primaryTable && item.secondaryTable)
            );
         };
      }
   };
}

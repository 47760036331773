import './select-button.less';
import template from './select-button.template.html';

export function selectButtonDirective($document, $timeout) {

   return {
      restrict: 'E',
      scope: {
         options: '=',
         onSelect: '&'
      },
      template: template,
      link: function(scope, element, attr) {

         let delay;
         let isBlocked = false;

         scope.active = false;
         scope.label = attr.label;

         scope.onToggleActive = function() {
            /*
            if (scope.options.length === 1) {
               scope.onSelect({
                  data: scope.options[0]
               });
               return;
            }*/
            block();
            scope.active = !scope.active;
         };

         scope.onOptionClick = function(val, e) {
            e.stopPropagation();
            scope.onSelect({
               data: val
            });
            close();
         };

         scope.$on('$destroy', function() {
            $document.off('mousedown', onBodyClick);
            if (delay) {
               $timeout.cancel(delay);
            }
         });

         $document.on('mousedown', onBodyClick);

         function onBodyClick() {
            if (!isBlocked && scope.active) {
               close();
            }
         }

         function block() {
            isBlocked = true;
            if (delay) {
               $timeout.cancel(delay);
            }
            delay = $timeout(unblock, 500);
         }

         function unblock() {
            isBlocked = false;
            safeApply();
         }

         function close() {
            scope.active = false;
            safeApply();
         }

         function safeApply() {
            $timeout(function() {
               //scope.$apply();
            }, 0);
         }
      }
   };

}

selectButtonDirective.$inject = ['$document', '$timeout'];

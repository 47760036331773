import deployTargets from 'core/configs/deployTargets.js';
import Constants from 'core/constants';
import {view as unpublishedChangesView} from './unpublished-changes/unpublished-changes.view';
import 'core/services/apiService.js';
import 'core/services/dataService.js';
import '../core/services/dialog.factory';
import './publish.less';

export function PublishController(stateService, apiService, dataService, dialogFactory) {

   const vm = this;
   vm.deployTargets = [];
   vm.init = init;
   vm.deployTargetRevisions = {};
   vm.status = 'NOT_STARTED';
   vm.onPublish = publish;
   vm.onClose = close;
   vm.states = stateService.getStates();
   vm.onDeployTargetChanged = checkPublishedRevision;
   vm.onShowUnpublishedChanges = showUnpublishedChanges;
   vm.isFirstPublish = false;
   vm.locales = [];

   function init(dialog) {
      const data = dataService.getData();
      vm.dialog = dialog;
      setEnvironments(data);
      vm.locales = data.locales.map(l => l.value);
      console.log('publish revision : ', vm.dialog.data.revision);
   }

   function setEnvironments(data) {
      const environment = vm.states.environment;
      vm.deployTargets = [];
      for (const target of deployTargets) {
         if (data.applications[vm.states.application].deployTargets.includes(target.id)
         && target.environments.includes(environment)) {
            vm.deployTargets.push(Object.assign({}, target));
         }
      }
      vm.selectedDeployTarget = vm.deployTargets[0];
      checkPublishedRevision();
   }

   function close() {
      vm.dialog.close();
   }

   function checkPublishedRevision() {
      vm.isFirstPublish = false;
      if (vm.dialog.data.revision !== null) {
         apiService.getPublishedVersion(vm.states.application, vm.dialog.data.locale, vm.selectedDeployTarget.id)
            .then(function(xhr) {
               const revision = xhr.data.revision;
               if (revision === null) {
                  vm.isFirstPublish = true;
               } else if (revision === vm.dialog.data.revision) {
                  vm.deployTargetRevisions[vm.selectedDeployTarget.id] = 0;
               } else {
                  compareRevisions(xhr.data);
               }
            })
            .catch(function() {
               vm.deployTargetRevisions[vm.selectedDeployTarget.id] = -1;
            });
      }
   }

   function compareRevisions(publishedData) {
      const data = dataService.getData();
      const latest = vm.dialog.data.locale === Constants.MASTER_LOCALE ? data.master : data.local;
      const changes = {};
      let count = 0;

      for (const section in latest) {
         for (const key in latest[section]) {
            if (!publishedData[section]
            || publishedData[section][key] === undefined) {
               changes[section] = changes[section] || {};
               changes[section][key] = {
                  value: latest[section][key].value,
                  publishedValue: '(no previous value)'
               };
               count++;
            } else if (latest[section][key].value !== publishedData[section][key]) {
               changes[section] = changes[section] || {};
               changes[section][key] = {
                  value: latest[section][key].value === '' ? '(empty string)' : latest[section][key].value,
                  publishedValue: publishedData[section][key] === '' ? '(empty string)' : publishedData[section][key]
               };
               count++;
            }
         }
      }
      for (const section in publishedData) {
         for (const key in publishedData[section]) {
            if (typeof publishedData[section] === 'object' && (!latest[section] || !latest[section][key])) {
               changes[section] = changes[section] || {};
               changes[section][key] = {
                  value: '(no value)',
                  publishedValue: publishedData[section][key]
               };
               count++;
            }
         }
      }
      vm.deployTargetRevisions[vm.selectedDeployTarget.id] = count;
      vm.changes = changes;
   }

   function showUnpublishedChanges() {
      dialogFactory.custom(Object.assign({
         data:{
            changes: vm.changes
         },
         proceedCallback: publish
      }, unpublishedChangesView));
   }

   function publish() {
      vm.status = 'PUBLISHING';
      apiService.publish(
         getJob(),
         vm.states.application,
         vm.dialog.data.locale,
         vm.dialog.data.revision,
         vm.locales
      ).then(function() {
         vm.status = 'SUCCESS';
         setPublishLink();
      }).catch(function() {
         vm.status = 'ERROR';
      });
   }

   function getJob() {
      return 'publish-translations-' + vm.selectedDeployTarget.id;
   }

   // TODO: These urls should not be hardcoded, or even be on frontend?
   function setPublishLink() {
      if (vm.status === 'SUCCESS') {
         const locale = vm.dialog.data.locale.toLowerCase().split('_');
         let baseUrl = '';
         if ((/(prod)|(verification)/).test(vm.selectedDeployTarget.id)) {
            baseUrl = (vm.selectedDeployTarget.id === 'verification'
               ? 'http://www.cte.ikeadt.com'
               : 'http://www.ikea.com') + '/addon-app/localizations/';
         }
         vm.publishedLink = baseUrl + vm.states.application + '/latest/' + locale[1] + '/' + locale[0] + '/';
      }
   }
}

PublishController.$inject = ['stateService', 'apiService', 'dataService', 'dialogFactory'];

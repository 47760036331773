import 'file-saver';
import 'core/services/dataService.js';
import 'core/services/sessionService.js';
import 'core/services/stateService.js';
import '../core/services/dialog.factory';
import {view as publishView} from 'publish/publish.module';

export function OptionsMenuDirective(stateService, dataService, sessionService, dialogFactory) {

   return {
      restrict: 'A',
      link: function(scope) {

         const data = dataService.getData();
         const user = sessionService.getUser();

         (function() {
            scope.states = stateService.getStates();

            scope.$watch('apps', function(apps) {
               if (apps[scope.states.application]) {
                  setOptions();
               }

            }, true);

            scope.$watch('states.application', function(app) {
               if (scope.apps[app]) {
                  setOptions();
               }
            });

            setUserLocales();
         })();

         scope.onClickPublish = onClickPublish;
         scope.apps = data.applications;

         function setOptions() {
            scope.exportOptions = scope.apps[scope.states.application].exportOptions;
            scope.importOptions = scope.apps[scope.states.application].importOptions;
         }

         function setUserLocales() {
            scope.userLocales = data.locales.filter(l => user.hasAccessToLocale(l.value));

            // If user don't have access to the current locale,
            // set it to the first locale that user _does_ have access to.
            if (scope.userLocales.length && !scope.userLocales.find(locale => locale.value === scope.states.locale)) {
               stateService.setState('locale', scope.userLocales[0].value);
            }
         }

         function onClickPublish() {
            dialogFactory.custom(Object.assign({
               header: 'Publish',
               data: {
                  locale: scope.states.locale,
                  revision: data.localRevisions.length ? data.localRevisions[0].value : data.localRevison
               }
            }, publishView));
         }
      }
   };
}

OptionsMenuDirective.$inject = [
   'stateService',
   'dataService',
   'sessionService',
   'dialogFactory'
];

import ExcelUtils from './ExcelUtils';
import TypeUtils from './TypeUtils';
import FileSaver from 'file-saver';
import ikeaLocales from '../../locales/locales.json';

/**
   * Opens a file dialog and returns the file buffer result.
   *
   * @example openFileDialog().then(result => {  }).catch(error => {  });
   * @param {String} type File type to open.
   * @param {Boolean} [readAsText] TRUE to read the data as text. Default is FALSE.
   * @return {Promise} Promise object.
   */
function openFileDialog(type, readAsText = false) {
   return new Promise((resolve, reject) => {
      let label;
      const input = document.createElement('input');

      //Fix for IE - It will trick IE to open the dialog if standard input.click() does not work
      const ieTimeout = setTimeout(() => {
         input.id = 'file_input';
         label = document.createElement('label');
         label.setAttribute('for', 'file_input');
         label.click();
         document.body.appendChild(label);
      }, 100);

      input.style.cssText = 'position:absolute;left:0;top:-999rem;';
      input.type = 'file';
      input.setAttribute('accept', '.' + type);

      input.addEventListener('change', event => {
         const files = event.target.files;

         clearTimeout(ieTimeout);

         if (files && files.length > 0) {
            const file = files[0];
            if (file.name.endsWith(type)) {
               const reader = new FileReader();

               reader.onload = event => {
                  resolve(event.target.result || null);
               };

               if (readAsText) {
                  reader.readAsText(file);
               } else {
                  reader.readAsDataURL(file);
               }
            } else {
               reject(new Error('The file is not of type ' + type + '. Please select a file with valid type.'));
            }
         } else {
            resolve(null);
         }

         document.body.removeChild(input);

         if (label) {
            document.body.removeChild(label);
         }
      });

      document.body.appendChild(input);
      input.click();
      setTimeout(function() {
         input.focus();
         input.blur();
      }, 0);
   });
}

async function importTranslation(application, locale, extension) {
   switch (extension) {
      case 'xlsx': return importTranslationFromExcel(application, locale);
      case 'json': return importTranslationFromJson(application, locale);
      default: return null;
   }
}

async function importTranslationFromExcel(application, locale) {
   const xlsx = await openFileDialog('xlsx');
   return ExcelUtils.parseWorkbook(application, locale, xlsx);
}

async function importTranslationFromJson() {
   const json = await openFileDialog('json', true);
   return JSON.parse(json);
}

async function exportTranslation(extension, ...args) {
   switch (extension) {
      case 'xlsx': return exportTranslationToExcel(...args);
      case 'json': return exportTranslationToJson(...args);
      default: return null;
   }
}

async function exportTranslationToExcel(application, locale, filename, master, local) {
   const buffer = await ExcelUtils.createWorkbook(application, locale, master, local);
   
   FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${filename}.xlsx`);
}

function exportTranslationToJson(application, locale, filename, master, local) {
   const data = Object.entries(master).reduce((obj, [section, labels]) => {
      obj[section] = {};
      Object.entries(labels).forEach(([label, item]) => {
         obj[section][label] = {
            application: item.application,
            section: item.section,
            labelGroup: item.labelGroup === 'Unlabeled' ? null : item.labelGroup,
            description: item.description,
            masterValue: item.value,
            type: item.type,
            chars: item.chars,
            options: item.options,
            value: local[section] && local[section][label] ? local[section][label].value : null
         };
      });
      return obj;
   }, {});

   const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json; charset=utf-8' });
   FileSaver.saveAs(blob, `${filename}.json`);
}

function exportMasterCopyToJson(filename, master) {
   const data = Object.entries(master).reduce((obj, [section, labels]) => {
      obj[section] = {};
      Object.values(labels).forEach(item => {
         obj[section][item.label] = {
            application: item.application,
            section: item.section,
            labelGroup: item.labelGroup === 'Unlabled' ? null : item.labelGroup,
            description: item.description,
            type: item.type,
            value: item.value,
            deprecated: item.deprecated,
            options: item.options,
            tags: item.tags,
            allowEmpty: item.allowEmpty,
            markets: item.markets,
         };
      });
      return obj;
   }, {});

   const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json; charset=utf-8' });
   FileSaver.saveAs(blob, `${filename}.json`);
}

function exportMasterCopy(extension, ...args) {
   switch (extension) {
      case 'xlsx': return null;
      case 'json': return exportMasterCopyToJson(...args);
      default: return null;
   }
}

const validJsonFields = [
   'section',
   'value',
   'type',
   'description',
   'options',
   'labelGroup',
   'chars',
   'allowEmpty',
   'markets',
   'approved',
   'deprecated'
];

function importMasterCopy(extension) {
   switch (extension) {
      case 'xlsx': return null;
      case 'json': return importMasterCopyJson();
      default: return null;
   }
}

async function importMasterCopyJson() {
   const str = await openFileDialog('json', true);
   const data = JSON.parse(str);
   const markets = Object.keys(ikeaLocales).map(locale => locale.split('_')[1]);

   for (const section in data) {
      for (const key in data[section]) {
         // eslint-disable-next-line no-inner-declarations
         function error(field, message) {
            throw new Error(`Field "${field}" in "${section}.${key}" ${message}.`);
         }

         const item = data[section][key];

         // default description and options to empty string
         if (item.description == null) {
            item.description = '';
         }
         if (item.options == null) {
            item.options = '';
         }
         if (typeof item.value === 'undefined') {
            error('value', 'is missing');
         }
         if (typeof item.type === 'undefined') {
            error('type', 'is missing');
         }
         if (typeof item.type !== 'string') {
            error('type', 'must be a string');
         }
         if (!(/(string)|(boolean)|(integer)|(select)/).test(item.type)) {
            error('type', 'must be one of the following types: "string", "integer", "boolean" or "select"');
         }
         if (!TypeUtils.isValid(item.value, item.type, item.options)) {
            error('value', 'does not match type ' + item.type);
         }
         if (typeof item.description !== 'string') {
            error('description', 'must be string');
         }
         if (typeof item.options !== 'string') {
            error('options', 'must be string');
         }
         if (item.labelGroup != null && typeof item.labelGroup !== 'string') {
            error('labelGroup', 'must be string');
         }
         if (item.labelGroup.length > 255) {
            error('labelGroup', 'exceeds max length of 255');
         }
         if (item.markets != null && typeof item.markets !== 'string') {
            error('markets', 'must be a string');
         }
         if (item.markets.length > 255) {
            error('markets', 'exceeds max length of 255');
         }
         if (Array.isArray(item.value) || typeof item.value === 'object') {
            error('value', 'must be string, integer or boolean');
         }

         if (item.markets) {
            for (const market of item.markets.split(',')) {
               if (!markets.find(m => m === market)) {
                  error('markets', 'must have valid markets, got ' + market);
               }
            }
         }

         for (const prop in item) {
            if (!validJsonFields.includes(prop)) {
               delete item[prop];
            }
         }
      }
   }

   return data;
}

export default {
   importTranslation,
   importMasterCopy,
   exportTranslation,
   exportMasterCopy
};

import Constants from 'core/constants';
import 'core/services/dataService.js';
import 'core/services/stateService.js';
import '../core/services/dialog.factory';
import {view as publishView} from 'publish/publish.module';
import {view as compareRevisionsView} from './compare-revisions/compare-revisions.view';

export function masterMenuDirective(stateService, dialogFactory, analyticsService) {

   return {
      restrict: 'A',
      link: function(scope) {

         const states = stateService.getStates();

         scope.exportOptions = ['json'];
         scope.importOptions = ['json'];
         scope.onClickPublish = onClickPublish;
         scope.onClickCompareRevisions = onClickCompareRevisions;

         function onClickPublish() {
            dialogFactory.custom(Object.assign({
               header: 'Publish',
               data: {
                  locale: Constants.MASTER_LOCALE,
                  revision: states.masterRevision
               }
            }, publishView));
         }

         function onClickCompareRevisions({ selectedTab, application, revision, revisionsList }) {
            analyticsService.trackEvent('master', 'compare_revision', 'open');
            dialogFactory.custom(Object.assign({ header: 'Compare revisions', data: { selectedTab, application, revision, revisionsList }, closeOnClickOutside: false }, compareRevisionsView));
         }
      }
   };

}

masterMenuDirective.$inject = ['stateService', 'dialogFactory', 'analyticsService'];

import './markets-picker.less';

export function marketsPicker($timeout) {
   return {
      restrict: 'E',
      template: `<div class="wrapper">
         <div class="selected-market-item" ng-class="{ disabled }" ng-repeat="selectedMarket in vm.selectedMarkets" ng-click="vm.removeListItem(selectedMarket)">

            <span>{{selectedMarket.label}}</span>
            <i ng-if="!disabled" class="fa fa-times-circle"></i>
         </div>
         <span class="autocomplete-list--wrapper">
            <span ng-if="!disabled">
               <input type="text" ng-if="showInput && allMarkets.length" ng-model="vm.searchString">
               <i class="fa fa-plus" ng-click="plusClicked()" ng-if="!showInput && allMarkets.length && vm.selectedMarkets.length < vm.limit"></i>
               <i class="fa fa-times-circle" ng-if="showInput" ng-click="plusClicked()"></i>
            </span>
            <div class="autocomplete-list--positioner">
               <div class="autocomplete-list" ng-if="showInput && allMarkets.length">
                  <ul>
                     <li class="autocomplete--list-item" ng-click="vm.onMarketSelected(market)" ng-repeat="market in allMarkets | filter: vm.searchString | orderBy : 'label'">
                        {{market.label}} ({{market.value}})
                     </li>
                  </ul>
               </div>
            </div>
         </span>
      </div>`,
      scope: {
         disabled: '<',
         limit: '<',
         allMarkets: '<',
         onMarketClicked: '&',
         initialMarkets: '<'
      },
      link: (scope, elm, attr, ctrl)=> {
         scope.plusClicked = () => {
            scope.showInput = scope.showInput ? false : true;
            ctrl.searchString = '';

            $timeout(()=> {
               const inputElement = elm[0].getElementsByTagName('input')[0];
               if (inputElement && inputElement.focus) {
                  inputElement.focus();
               }
            });
         };
      },
      controller: marketsPickerController,
      controllerAs: 'vm'
   };
}

function marketsPickerController($scope) {
   const vm = this;
   vm.searchString = '';

   const initialSelectedCountryCodes = $scope.initialMarkets.length ? $scope.initialMarkets.split(',') : [];
   vm.selectedMarkets = $scope.allMarkets.filter(elm => initialSelectedCountryCodes.some(item => elm.value === item)) || [];
   vm.limit = $scope.limit;

   vm.selectedMarkets.forEach(market => {
      const index = $scope.allMarkets.indexOf(market);
      if (index > -1) {
         $scope.allMarkets.splice(index, 1);
      }
   });

   vm.onMarketSelected = (market) => {
      if (vm.selectedMarkets.length >= $scope.limit) {
         return;
      }

      $scope.showInput = false;
      vm.selectedMarkets.push(market);

      const index = $scope.allMarkets.indexOf(market);
      if (index > -1) {
         $scope.allMarkets.splice(index, 1);
      }

      $scope.onMarketClicked({ selectedMarkets: vm.selectedMarkets });
   };

   vm.removeListItem = (market) => {
      $scope.allMarkets.push(market);
      const index = vm.selectedMarkets.indexOf(market);
      if (index > -1) {
         vm.selectedMarkets.splice(index, 1);
      }

      $scope.onMarketClicked({ selectedMarkets: vm.selectedMarkets});
   };
}

marketsPicker.$inject = ['$timeout'];

marketsPickerController.$inject = ['$scope'];
import angular from 'angular';

const moduleName = 'configs';
export default moduleName;

angular.module(moduleName, [])

   .config(['$provide', function($provide) {
      return $provide.decorator('$rootScope', [
         '$delegate', function($delegate) {
            $delegate.$safeApply = function(fn) {
               const phase = $delegate.$$phase;
               if (phase === '$apply' || phase === '$digest') {
                  if (fn && typeof fn === 'function') {
                     fn();
                  }
               } else {
                  $delegate.$apply(fn);
               }
            };
            return $delegate;
         }
      ]);
   }]);

import Constants from '../core/constants';
import '../core/services/dataService.js';
import '../core/services/stateService.js';
import '../core/services/dialog.factory';
import {view as editApplication} from './edit-application/edit-application.view';
import {view as editMasterItem} from './edit-master-item/edit-master-item.view';
import {view as editMasterLabel} from './edit-master-label/edit-master-label.view';
import {view as addMasterSection} from './add-master-section/add-master-section.view';
import {view as moveKeys} from './move-keys/move-keys.view';
import {view as importDialogView} from './import-dialog/import-dialog.view';
import './master.less';
import FileUtils from '../core/utils/FileUtils';
import _ from 'lodash';

export function MasterViewController($scope, dataService, stateService, dialogFactory, filterService, sessionService, apiService, toastersFactory, analyticsService) {

   const vm = this;

   vm.states = stateService.getStates();
   vm.data = dataService.getData();
   vm.masterGroups = {};

   vm.init = init;
   vm.onSelectRevision = onSelectRevision;
   vm.onEditSettings = onEditSettings;
   vm.onItemValueChanged = onItemValueChanged;
   vm.onEditItem = onEditItem;
   vm.onAddItem = onAddItem;
   vm.onRemoveItem = onRemoveItem;
   vm.onAddSection = onAddSection;
   vm.onEditLabel = onEditLabel;
   vm.isLoading = false;
   vm.onSelectLabel = onSelectLabel;
   vm.itemStatuses = [
      {
         label: 'Default',
         value: null
      },
      {
         label: 'Approved',
         value: 'approved'
      },
      {
         label: 'Not approved',
         value: 'notapproved'
      },
      {
         label: 'Deprecated',
         value: 'deprecated'
      },
      {
         label: 'All',
         value: 'all'
      }
   ];

   vm.filteredResults = {};
   vm.hiddenTabs = [];
   vm.tabs = [];
   vm.tabSelected = 0;
   vm.onChangeFilter = onChangeFilter;
   vm.onResetFilters = onResetFilters;
   vm.filterMatchesForGroup = filterMatchesForGroup;
   vm.user = sessionService.getUser();
   vm.noData = false;
   vm.isSaving = false;
   vm.hasChanges = false;
   vm.isLatestRevision = true;
   
   this.payload = {
      new: [],
      updated: [],
      removed: []
   };

   vm.isHovering = obj => {
      return obj.hovering && vm.user.can('edit', 'master_copy');
   };

   vm.getTextareaRows = (section, key) => {
      const value = vm.data.master[section][key] && vm.data.master[section][key].value || '';
      return value.length > 72 ? 3 : 1;
   };

   vm.onInputBlur = (newValue, oldValue) => {
      if (newValue !== oldValue) {
         analyticsService.trackEvent('master', 'edit_key_value', 'inline');
      }
   };

   $scope.$watch('vm.filters.searchString', _.debounce((search) => {
      if (search == null || search === '') {
         return;
      }

      analyticsService.trackEvent('master', 'search', search);
   }, 1000));

   function onChangeFilter(filter, store) {
      filterService.onChangeFilter(filter, store);

      // This is called when searching as well (with value 'searchString')
      if (filter !== 'searchString') {
         analyticsService.trackEvent('master', 'change_filter', vm.filters.itemStatus);
      }
   }

   function onResetFilters() {
      filterService.onResetFilters();
   }

   vm.isFilterActive = () => {
      return filterService.isAnyFilterActive(vm.filters, 'master');
   };

   function filterMatchesForGroup(tab) {
      return vm.filteredResults[tab];
   }

   $scope.$watch('vm.data.masterGroups', (masterGroups) => {
      vm.tabs = [];

      for (const key in masterGroups) {
         vm.tabs.push({
            key,
            title: key,
            listLength: masterGroups[key].reduce((acc, curr) => acc + curr.items.length, 0),
            labels: masterGroups[key]
         });
      }
   });

   $scope.$watch('vm.states.application', function(val, old) {
      if (val !== old) {
         init();
         vm.resetSelection();
      }
   });

   function init() {
      resetPayload();
      vm.noData = false;
      vm.filters = filterService.getFilters();

      if (vm.states.application/* && vm.user.hasAccessToApp(vm.states.application) */) {
         vm.isLoading = true;
         dataService.getRevisions('master', Constants.MASTER_LOCALE);
         syncData().then(() => {
            vm.hasChanges = false;
            vm.isLoading = false;
            vm.isLatestRevision = true;
         });
      } else {
         vm.noData = true;
      }
   }

   function syncData() {
      return dataService.getMaster().then(() => {
         dataService.updateMasterGroups();
      });
   }

   function onSelectRevision() {
      const revision = vm.data.masterRevisions[0] && vm.states.masterRevision !== vm.data.masterRevisions[0].value
      ? vm.states.masterRevision
      : undefined;
      vm.isLatestRevision = vm.states.masterRevision === vm.data.masterRevisions[0].value;

      dataService.getMaster(revision)
         .then(dataService.updateMasterGroups)
         .then(() => {
            analyticsService.trackEvent('master', 'change_revision', revision);
         });

      vm.resetSelection();
   }

   function onEditSettings() {
      analyticsService.trackEvent('master', 'open_settings', vm.states.application);
      dialogFactory.custom(Object.assign({
         header: 'Edit application',
         proceedCallback: function() {
            dataService.getApplications(true);
            analyticsService.trackEvent('master', 'save_settings', vm.states.application);
         }
      }, editApplication));
   }

   const resetPayload = () => {
      this.payload = {
         new: [],
         updated: [],
         removed: []
      };
   };

   const updatePayload = (type, item) => {
      const existsInNew = this.payload.new.find(i => i.section === item.section && i.label === item.label);
      const existsInUpdated = this.payload.updated.find(i => i.section === item.section && i.label === item.label);
      const filterOutItem = (arr, item) => arr.filter(i => i.section !== item.section || i.label !== item.label);

      switch (type) {
         case 'new': {
            if (!existsInNew) {
               this.payload.new = [...this.payload.new, item];
            }
            break;
         }
         case 'updated': {
            if (existsInNew) {
               // Do nothing
            } else if (!existsInNew && !existsInUpdated) {
               this.payload.updated = [...this.payload.updated, item];
            }
            break;
         }
         case 'removed': {
            if (existsInNew) {
               this.payload.new = filterOutItem(this.payload.new, item);
            } else if (!existsInUpdated) {
               this.payload.removed = [...this.payload.removed, item];
            } else {
               this.payload.updated = filterOutItem(this.payload.updated, item);
               this.payload.removed = [...this.payload.removed, item];
            }
            break;
         }
      }

      console.log(this.payload);

      vm.hasChanges = !!['new', 'updated', 'removed'].find(key => this.payload[key].length);
   };

   function onItemValueChanged(section, label, item, original) {
      if (original) {
         item.changed = item.value !== original.value;
      }
      updatePayload('updated', { section, label });
   }

   function onEditItem(event, section, label, item) {
      if (['input', 'select', 'textarea'].includes(event.target.localName)) {
         return;
      }

      if (!vm.user.can('edit', 'master_copy') || !vm.user.hasAccessToApp(vm.states.application) || !vm.isLatestRevision) {
         return;
      }

      analyticsService.trackEvent('master', 'edit_key', 'open');

      dialogFactory.custom(Object.assign({
         header: 'Edit item',
         data: {
            section,
            label: label,
            item: item
         },
         proceedCallback: (data) => {
            if (data.item.value !== vm.data.master[section][label].value) {
               analyticsService.trackEvent('master', 'edit_key_value', 'dialog');
            }

            onItemUpdated(data);

            analyticsService.trackEvent('master', 'edit_key', 'submit');
         }
      }, editMasterItem));
   }

   function onAddItem(section) {
      analyticsService.trackEvent('master', 'create_key', 'open');
      dialogFactory.custom(Object.assign({
         header: 'Add new key',
         data: {
            section
         },
         proceedCallback: onItemAdded
      }, editMasterItem));
   }

   function onRemoveItem(section, label) {
      dialogFactory.confirm({
         header: 'Remove item',
         body: 'Are you sure that you want to remove the "' + label + '" item?',
         proceedText: 'Remove',
         proceedCallback: () => {
            delete vm.data.master[section][label];
            dataService.updateMasterGroups();
            updatePayload('removed', {
               section,
               label
            });
         }
      });
   }

   function onEditLabel(label, section) {
      const action = label === null ? 'create_key_group' : 'edit_key_group';
      analyticsService.trackEvent('master', action, 'open');

      dialogFactory.custom(Object.assign({
         header: 'Edit label',
         proceedCallback: (changed) => {
            if (changed) {
               analyticsService.trackEvent('master', action, 'submit');
               changed.forEach(item => updatePayload('updated', item));
            }
         },
         data: {
            section,
            label: label
         }
      }, editMasterLabel));
   }

   function onSelectLabel(event, labelGroup) {
      if (['input', 'select', 'i'].includes(event.target.localName)) {
         return;
      }

      analyticsService.trackEvent('master', 'toggle_key_group', labelGroup.expanded ? 'close' : 'open');
      
      labelGroup.expanded = !labelGroup.expanded;
   }

   function onAddSection() {
      analyticsService.trackEvent('master', 'create_section', 'open');
      dialogFactory.custom(Object.assign({
         header: 'Add section',
         proceedCallback: onSectionAdded
      }, addMasterSection));
   }

   function onItemUpdated(data) {
      console.log('onItemUpdated : data', data);
      Object.assign(vm.data.master[data.section.key][data.label], data.item);
      dataService.updateMasterGroups();
      updatePayload('updated', {
         section: data.section.key,
         label: data.label
      });
   }

   function onItemAdded(data) {
      console.log('onItemAdded : data', data);
      data.item.label = data.label;
      vm.data.master[data.section.key][data.label] = data.item;
      dataService.updateMasterGroups();
      updatePayload('new', {
         section: data.section.key,
         label: data.label
      });
      analyticsService.trackEvent('master', 'create_key', 'submit');
   }

   function onSectionAdded(data) {
      console.log('onSectionAdded : data', data);

      if (!vm.data.master[data.label]) {
         vm.data.master[data.label] = {};
      }

      dataService.updateMasterGroups();
      analyticsService.trackEvent('master', 'create_section', 'submit');
   }

   let selectedList = [];

   function removeFromSelectedList(item) {
      item.selected = false;
      const index = selectedList.findIndex(selected => selected.item.key === item.key);
      if (index > -1) {
         selectedList.splice(index, 1);
      }
   }

   function addToSelectedList(item, section) {
      if (item.selected) {
         return;
      }

      item.selected = true;

      const selectedItem = {
         section: section,
         item
      };

      selectedList.push(selectedItem);
   }

   function getAllFilteredItems() {
      const section = vm.tabs.find(tab => tab.key === vm.tabSelected.key);
      return section.labels.reduce((items, label) => items.concat(label.items), []);
   }

   function getLabelFilteredItems(label) {
      const section = vm.tabs.find(tab => tab.key === vm.tabSelected.key);
      return section.labels.find(lbl => lbl.label === label).items;
   }

   Object.defineProperty(vm, 'labelKeysSelected', {
      get () {
         const items = getAllFilteredItems();
         return items.reduce((obj, item) => {
            if (!obj.hasOwnProperty(item.labelGroup)) {
               obj[item.labelGroup] = item.selected;
            } else {
               obj[item.labelGroup] = obj[item.labelGroup] && item.selected;
            }

            return obj;
         }, {});
      }
   });

   Object.defineProperty(vm, 'allKeysSelected', {
      get () {
         return getAllFilteredItems().every(item => item.selected);
      }
   });

   Object.defineProperty(vm, 'someKeysSelected', {
      get() {
         return getAllFilteredItems().some(item => item.selected);
      }
   });

   Object.defineProperty(vm, 'sectionHasLabels', {
      get () {
         return !(vm.data.allLabels[vm.tabSelected.key].length === 1 && vm.data.allLabels[vm.tabSelected.key][0] === 'Unlabeled');
      }
   });

   vm.resetSelection = () => {
      selectedList.forEach(selected => selected.item.selected = false);
      selectedList = [];
   };

   vm.onLabelKeysSelectedClick = (label, section) => {
      const items = getLabelFilteredItems(label);

      if (vm.labelKeysSelected[label]) {
         items.forEach(item => removeFromSelectedList(item));
      } else {
         items.forEach(item => addToSelectedList(item, section));
      }

      analyticsService.trackEvent('master', 'list_select', 'label_group');
   };

   vm.onAllKeysClicked = (section) => {
      const items = getAllFilteredItems();

      if (!vm.allKeysSelected) {
         items.forEach(item => addToSelectedList(item, section));
      } else {
         items.forEach(item => removeFromSelectedList(item));
      }

      analyticsService.trackEvent('master', 'list_select', 'all_keys');
   };

   vm.onRowSelected = (section, label, key) => {
      const item = vm.tabs
         .find(_section => _section.key === section.key).labels
         .find(_label => _label.label === label).items
         .find(item => item.key === key);

      if (item.selected) {
         removeFromSelectedList(item);
      } else {
         addToSelectedList(item, section.key);
      }

      analyticsService.trackEvent('master', 'list_select', 'single_key');
   };

   vm.currentMultipleAction = 'none';

   vm.onMultipleActionChange = () => {
      if (!vm.currentMultipleAction) {
         vm.currentMultipleAction = 'none';
         return;
      }

      analyticsService.trackEvent('master', 'list_apply_action', vm.currentMultipleAction);

      switch (vm.currentMultipleAction) {
         case 'approve': {
            const approvable = selectedList.filter(selected => !selected.item.deprecated && !selected.item.approved);

            vm.applyMultipleAction({
               header: 'Approve key(s)',
               body: `${approvable.length} key(s) will be approved for translation.`,
               proceedText: 'Approve',
               proceedCallback: () => {
                  approvable.forEach(selected => {
                     selected.item.approved = true;
                     Object.assign(vm.data.master[selected.section][selected.item.key], selected.item);
                     updatePayload('updated', {
                        section: selected.section,
                        label: selected.item.key
                     });
                  });
               }
            });
            break;
         }
         case 'deprecate': {
            const deprecatable = selectedList.filter(selected => !selected.item.deprecated);

            vm.applyMultipleAction({
               header: 'Deprecate key(s)',
               body: `${deprecatable.length} key(s) will be deprecated.`,
               proceedText: 'Deprecate',
               proceedCallback: () => {
                  deprecatable.forEach(selected => {
                     selected.item.deprecated = true;
                     Object.assign(vm.data.master[selected.section][selected.item.key], selected.item);
                     updatePayload('updated', {
                        section: selected.section,
                        label: selected.item.key
                     });
                  });
               }
            });
            break;
         }
         case 'delete': {
            const deletable = selectedList;

            vm.applyMultipleAction({
               header: 'Delete key(s)',
               body: `${deletable.length} key(s) will be deleted.`,
               proceedText: 'Delete',
               proceedCallback: () => {
                  deletable.forEach(selected => {
                     delete vm.data.master[selected.section][selected.item.key];
                     updatePayload('removed', {
                        section: selected.section,
                        label: selected.item.key
                     });
                  });
               }
            });
            break;
         }
         case 'move': {
            const moveable = selectedList;
            const labelGroups = vm.data.allLabels[vm.tabSelected.key].filter(v => !!v.length);
            
            if (!labelGroups.includes('Unlabeled')) {
               labelGroups.push('Unlabeled');
            }

            dialogFactory.custom(Object.assign({
               header: 'Move key(s)',
               data: {
                  labelGroups,
                  selectedList
               },
               proceedCallback: (newLabel) => {
                  if (newLabel) {
                     moveable.forEach(selected => {
                        vm.data.master[selected.section][selected.item.key].labelGroup = newLabel;
                        updatePayload('updated', {
                           section: selected.section,
                           label: selected.item.key
                        });
                     });

                     dataService.updateMasterGroups();
                     vm.resetSelection();
                  }

                  vm.currentMultipleAction = 'none';
               },
               cancelCallback: () => {
                  vm.currentMultipleAction = 'none';
               }
            }, moveKeys));
            break;
         }
         case 'reset': {
            const resettable = selectedList.filter(selected => selected.item.deprecated || selected.item.approved);

            vm.applyMultipleAction({
               header: 'Reset key(s)',
               body: `${resettable.length} key(s) will be reset. This will both unapprove and undeprecate the key(s).`,
               proceedText: 'Reset',
               proceedCallback: () => {
                  resettable.forEach(selected => {
                     vm.data.master[selected.section][selected.item.key].approved = null;
                     vm.data.master[selected.section][selected.item.key].deprecated = null;
                     updatePayload('updated', {
                        section: selected.section,
                        label: selected.item.key
                     });
                  });
               }
            });
            break;
         }
      }
   };

   vm.applyMultipleAction = ({ header, body, proceedText, proceedCallback }) => {
      dialogFactory.confirm({
         header,
         body,
         proceedText,
         proceedCallback: () => {
            proceedCallback();

            dataService.updateMasterGroups();
            vm.resetSelection();

            vm.currentMultipleAction = 'none';
         },
         cancelCallback: () => {
            vm.currentMultipleAction = 'none';
         }
      });
   };

   vm.currentSelectionIs = (property) => {
      return selectedList.every(selected => !!selected.item[property]);
   };

   vm.currentSelectionIsResettable = () => {
      return selectedList.some(selected => selected.item.approved || selected.item.deprecated);
   };

   vm.onClickSave = () => {
      ['new', 'updated'].forEach(key => {
         this.payload[key] = this.payload[key].map(item => {
            return Object.assign({}, item, vm.data.master[item.section][item.label]);
         });
      });

      vm.isSaving = true;
      apiService.saveMaster(vm.states.application, this.payload).then(() => {
         resetPayload();
         dataService.getRevisions('master', Constants.MASTER_LOCALE);
         vm.isSaving = false;
         vm.hasChanges = false;
      });
   };

   vm.onSelectExportAs = (extension) => {
      if (vm.hasChanges) {
         return dialogFactory.alert({
            header: 'Unsaved changes',
            body: `There are unsaved changes, please save your changes before an export`
         });
      }

      const filename = `${vm.states.application}_master_${vm.states.masterRevision}`;
      const count = (data) => Object.entries(data).reduce((curr, [, labels]) => curr + Object.keys(labels).length, 0);
      const transform = (arr) => arr.reduce((obj, section) => {
         obj[section.key] = [];
         section.labels.forEach(label => {
            label.items.forEach(item => {
               obj[section.key][item.label] = item;
            });
         });
         return obj;
      }, {});

      const data = transform(vm.tabs);

      dialogFactory.confirm({
         header: 'Export keys',
         body: `${count(data)} of ${count(vm.data.master)} keys will be exported.`,
         proceedText: 'Export',
         proceedCallback: () => {
            FileUtils.exportMasterCopy(extension, filename, data);
            analyticsService.trackEvent('master', 'export', 'json');
         }
      });
   };

   vm.onSelectImportAs = (extension) => {
      FileUtils.importMasterCopy(extension)
         .then((data) => {
            dialogFactory.custom(Object.assign({
               header: 'Import',
               data: {
                  importData: data,
                  masterData: vm.data.master
               }, 
               closeOnClickOutside: false,
               proceedCallback: (data) => {
                  data.new.forEach(item => {
                     if (!vm.data.master[item.section]) {
                        vm.data.master[item.section] = {};
                     }
                     vm.data.master[item.section][item.label] = item;
                     updatePayload('new', item);
                  });

                  data.updated.forEach(item => {
                     vm.data.master[item.section][item.label] = item;
                     updatePayload('updated', item);
                  });

                  dataService.updateMasterGroups();

                  toastersFactory.show({
                     layout: 'success',
                     body: 'Your master copy data was imported successfully! Save the master copy to make the changes permanent'
                  });

                  analyticsService.trackEvent('master', 'import', 'json');
               }
            }, importDialogView));
         })
         .catch((err) => {
            toastersFactory.show({
               body: 'An error occurred during import and the operation has been canceled.<br><br>' + err.message,
               layout: 'failure',
               closeable: true
            });
            $scope.$apply();
         });
   };
}

MasterViewController.$inject = [
   '$scope',
   'dataService',
   'stateService',
   'dialogFactory',
   'filterService',
   'sessionService',
   'apiService',
   'toastersFactory',
   'analyticsService',
];

import 'core/services/dataService.js';
import 'core/services/stateService.js';
import './edit-master-label.less';
import template from './edit-master-label.template.html';

export const view = {
   template: template
};

export function EditMasterLabelController($scope, dataService, stateService) {

   const vm = this;
   vm.states = stateService.getStates();
   vm.data = dataService.getData();
   vm.hasChanged = false;
   vm.selectedKeys = [];

   vm.init = init;
   vm.onItemChanged = onItemChanged;
   vm.onFilterKeys = findKeys;
   vm.onOperationChanged = onOperationChanged;
   vm.onToggleSelectAll = onToggleSelectAll;
   vm.onConfirm = confirmAndClose;
   vm.onCancel = close;
   
   function init(dialog) {
      vm.dialog = dialog;
      vm.section = dialog.data.section;
      vm.label = dialog.data.label;
      vm.operation = vm.label === null ? 'add' : 'rename';
      vm.unlabeledOnly = vm.operation === 'add';
      vm.sectionOnly = true;
      vm.renamedLabel = '';
      vm.matchKeys = '';
      vm.selectAll = true;
      vm.hasSections = Object.keys(vm.data.master).length > 1;
      vm.hasChanged = false;
      findKeys();
   }

   function onOperationChanged() {
      findKeys();
   }

   function onToggleSelectAll() {
      for (const key of vm.selectedKeys) {
         key.changeLabel = vm.selectAll;
      }
   }

   function findKeys() {
      const keys = [];
      const searchString = vm.matchKeys.toLowerCase();
      for (const section in vm.data.master) {
         if (vm.sectionOnly && section !== vm.section) {
            continue;
         }
         for (const key in vm.data.master[section]) {
            if (matchesCondition(vm.data.master[section][key])
            && matchesSearch(key, searchString)) {
               keys.push({
                  section,
                  key,
                  labelGroup: vm.data.master[section][key].labelGroup,
                  changeLabel: true
               });
            }
         }
      }
      vm.selectedKeys = keys;
   }

   function matchesCondition(key) {
      switch (vm.operation) {
         case 'add':
            return vm.unlabeledOnly ? (!key.labelGroup || key.labelGroup === 'Unlabeled') : key.labelGroup !== vm.label;
         
         case 'rename':
            return key.labelGroup === vm.label;
         
         case 'remove':
            return key.labelGroup === vm.label;
      }
      return false;
   }

   function matchesSearch(key, searchString) {
      return key.toLowerCase().includes(searchString);
   }

   function getAllSelected() {
      const items = [];
      for (const key of vm.selectedKeys) {
         if (key.changeLabel) {
            items.push(key);
         }
      }
      return items;
   }

   function onItemChanged () {
      vm.hasChanged = true;
   }

   function close() {
      vm.dialog.close();
   }

   function setKeyLabels() {
      const keys = getAllSelected();
      const changed = [];
      for (const item of keys) {
         if (vm.operation === 'add') {
            vm.data.master[item.section][item.key].labelGroup = vm.label;
         }
         else if (vm.operation === 'rename') {
            vm.data.master[item.section][item.key].labelGroup = vm.renamedLabel;
         }
         else if (vm.operation === 'remove') {
            vm.data.master[item.section][item.key].labelGroup = null;
         }
         changed.push({
            section: item.section,
            label: item.key
         });
      }
      dataService.updateMasterGroups();
      return changed;
   }

   function confirmAndClose() {
      const changed = setKeyLabels();
      vm.dialog.proceedCallback(changed);
      vm.dialog.close();
   }
}

EditMasterLabelController.$inject = [
   '$scope',
   'dataService',
   'stateService'
];


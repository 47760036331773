import angular from 'angular';
import regeneratorRuntime from 'regenerator-runtime';

// This is a hack to get around the fact that
// ikea-shared-analytics compiled code has
// references to a global regeneratorRuntime
// variable that is missing from their output
// (most likely because they hoist common code
// higher up their chain, on the assumption that
// their build tools are always used)
window.regeneratorRuntime = regeneratorRuntime;

import {
   AppAnalyticsTracker,
   ExceptionsTracker,
   GoogleAnalyticsReporter,
} from '@ikea-aoa/ikea-shared-analytics';

const moduleName = 'analyticsService';

export default moduleName;

angular.module(moduleName, [])
   .factory(moduleName, analyticsService);

function analyticsService() {
   // eslint-disable-next-line no-undef
   const trackingId = TRANSLATE_GOOGLE_ANALYTICS_ID;

   if (!trackingId) {
      console.warn('Analytics not enabled. Missing trackingId.');
      return {
         trackEvent: () => {/** */},
         trackPage: () => {/** */},
      };
   }

   const googleAnalyticsReporter = new GoogleAnalyticsReporter({ trackingId });

   // Configure all reporters
   const reporters = [
      googleAnalyticsReporter,
   ];

   // Configure all component trackers
   const appAnalyticsTracker = new AppAnalyticsTracker();

   const trackers = [
      appAnalyticsTracker,
      new ExceptionsTracker(),
   ];

   // Connect all reporters to their servers
   Promise.all(
      reporters.map(reporter => reporter.connect())
   ).then(() => {
      trackers.forEach(tracker => {
         // Connect all trackers to a all reporters
         reporters.forEach(reporter => tracker.addReporter(reporter));
         // Activate the tracking
         tracker.listen();
      });
   });

   return {
      trackEvent: (category, action, label) => appAnalyticsTracker.reportCustomEvent({ category, action, label }),
      trackPage: (page) => googleAnalyticsReporter.reportPageView(page),
   };
}

import angular from 'angular';
import 'core/services/dataService.js';
import 'core/services/apiService.js';
import 'core/services/sessionService.js';
import '../../core/services/dialog.factory';
import './edit-user.less';

export function EditUserController(apiService, dataService, sessionService, dialogFactory, toastersFactory) {

   const vm = this;

   vm.init = init;
   vm.onTogglePermission = onTogglePermission;
   vm.onToggleLocale = onToggleLocale;
   vm.onToggleApp = onToggleApp;
   vm.onClose = close;
   vm.onCancelUser = close;
   vm.onSaveUser = saveUser;
   vm.onDeleteUser = deleteUser;
   vm.onRoleChanged = role => {
      if (role) {
         vm.model.roles = [role];
      }
   };
   vm.changePassword = false;
   vm.showPasswordPopup = false;
   vm.getError = (field) => {
      const errors = vm.errors[field];

      if (errors && errors.length) {
         return errors[0].includes('These rules must pass') ? errors[1] : errors[0];
      }

      return null;
   };

   function init(dialog) {
      vm.dialog = dialog;
      vm.model = dialog.data.user;
      vm.locales = angular.copy(dialog.data.locales);
      vm.applications = angular.copy(dialog.data.applications);
      
      // TOOD: We do this because we get a simple string array from backend
      vm.model.locales = vm.locales.filter(loc => vm.model.locales.find(locale => locale === loc.value));

      vm.roles = dialog.data.roles;
      vm.role = vm.model.roles.length ? vm.model.roles[0] : undefined;
      vm.changePassword = !vm.model.id;

      vm.user = sessionService.getUser();
   }

   function onTogglePermission(perm) {
      vm.model[perm] = !vm.model[perm];
   }

   function onToggleLocale(locale) {
      const index = vm.model.userLocales.indexOf(locale.value);
      if (index > -1) {
         vm.model.userLocales.splice(index, 1);
         locale.checked = false;
      } else {
         vm.model.userLocales.push(locale.value);
         locale.checked = true;
      }
   }

   function onToggleApp(app) {
      const index = vm.model.masterPermissions.indexOf(app.name);
      if (index > -1) {
         vm.model.masterPermissions.splice(index, 1);
         app.checked = false;
      } else {
         vm.model.masterPermissions.push(app.name);
         app.checked = true;
      }
   }

   function saveUser() {
      console.log('saveUser', vm.model);

      apiService.saveUser(vm.model)
         .then(onSaveSuccess)
         .catch(err => {
            if (err.status === 422) {
               vm.errors = err.data.errors;
            }
         });
   }

   function onSaveSuccess() {
      vm.dialog.proceedCallback(1);
      close();
      toastersFactory.show({
         layout: 'success',
         body: 'User was saved successfully'
      });
   }

   function deleteUser() {
      dialogFactory.confirm({
         header: 'Confirm delete',
         body: 'Are you sure you want to delete user ' + vm.model.username + '?',
         proceedCallback: function() {
            close();
            apiService.deleteUser(vm.model.id)
               .then(onDeleteSuccess)
               .catch(onUpdateError);
         }
      });
   }

   function onDeleteSuccess() {
      vm.dialog.proceedCallback(-1);
      close();
      toastersFactory.show({
         layout: 'success',
         body: 'User was deleted successfully'
      });
   }

   function onUpdateError(xhr) {
      toastersFactory.show({
         layout: 'failure',
         body: 'Failed to update user ' + xhr.statusText
      });
   }

   function close() {
      vm.dialog.close();
   }
}

EditUserController.$inject = ['apiService', 'dataService', 'sessionService', 'dialogFactory', 'toastersFactory'];

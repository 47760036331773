import ExcelJS from 'exceljs/dist/es5/exceljs.browser';

const COLOR_ORANGE = 'FFFF9933';
const COLOR_RED = 'FFFF3333';

const formats = {
   OLD_FORMAT_COLUMN_COUNT: 8,
   NEW_FORMAT_COLUMN_COUNT: 9,
};

const nonEditable = (address) => {
   return {
      type: 'custom',
      allowBlank: false,
      formulae: ['$' + address],
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: 'Value is not editable',
      error: 'This value can not be modified. Please only change locale translation values.'
   };
};

const columns = [
   {
      key: 'application',
      header: 'Application',
      width: 20,
      transform: (master) => master.application,
      validation: nonEditable
   },
   {
      key: 'locale',
      header: 'Locale',
      width: 20,
      transform: (master, local) => local.locale,
      validation: nonEditable
   },
   {
      key: 'section',
      header: 'Section',
      width: 20,
      transform: (master) => master.section,
      validation: nonEditable
   },
   {
      key: 'labelGroup',
      header: 'Label Group',
      width: 20,
      transform: (master) => master.labelGroup,
      validation: nonEditable
   },
   {
      key: 'label',
      header: 'Label',
      width: 20,
      transform: (master) => master.label,
      validation: nonEditable
   },
   {
      key: 'type',
      header: 'Type',
      width: 10,
      transform: (master) => master.type,
      validation: nonEditable
   },
   {
      key: 'masterValue',
      header: 'Master Value',
      width: 30,
      transform: (master) => getValue(master.type, master.value),
      validation: nonEditable
   },
   {
      key: 'characterLimit',
      header: 'Character Limit',
      width: 20,
      transform: (master) => master.chars || '',
      validation: nonEditable
   },
   {
      key: 'localeValue',
      header: 'Locale Value',
      width: 30,
      transform: (master, local) => getValue(master.type, local.value)
   },
   {
      key: 'description',
      header: 'Description',
      width: 30,
      transform: (master) => master.description,
      validation: nonEditable
   }
];

function createRow(master, local, columns) {
   return columns.reduce((row, { key, transform }) => {
      row[key] = transform(master, local || {});
      return row;
   }, {});
}

function createLocaleValueCellData(masterItem, localItem) {
   const data = {};

   createLocaleValueValidation(data, masterItem);
   createLocaleValueStyle(data, masterItem, localItem);

   return data;
}

function createLocaleValueStyle(data, masterItem, localItem) {
   let color = null;
   if (localItem && localItem.updated < masterItem.updated) {
      color = COLOR_ORANGE;
   } else if (localItem.value == null) {
      color = COLOR_RED;
   }

   if (color) {
      data.border = {
         top: { style: 'medium', color: { argb: color } },
         left:  {style: 'medium', color: { argb: color } },
         bottom: { style: 'medium', color: { argb: color } },
         right: { style: 'medium', color: { argb: color } }
      };
   }
}

function createLocaleValueValidation(data, item) {
   let validation = null;

   switch (item.type) {
      case 'boolean':
         validation = {
            type: 'list',
            operator: 'custom',
            allowBlank: false,
            formulae: ['"TRUE,FALSE"'],
            error: 'The value provided is not valid. Please use the dropdown list to select a value.'
         };
         break;
      case 'integer':
         validation = {
            type: 'whole',
            operator: 'between',
            allowBlank: true,
            formulae: [
               -1000000000, 1000000000
            ],
            error: 'The value must be an integer.'
         };
         break;
      case 'select':
         validation = {
            type: 'list',
            operator: 'custom',
            allowBlank: false,
            formulae: ['"' + item.options + '"'],
            error: 'The value provided is not valid. Please use the dropdown list to select a value.'
         };
         break;
   }

   if (validation !== null) {
      data.dataValidation = Object.assign(validation, {
         showErrorMessage: true,
         errorStyle: 'error',
         errorTitle: 'Invalid value',
      });
   }

   return data;
}

function getValue(type, value) {
   if (type === 'boolean') {
      return value === 0 || value === false ? 'FALSE' : 'TRUE';
   }

   return value;
}

async function createWorkbook(application, locale, master, local) {
   const workbook = new ExcelJS.Workbook();

   workbook.creator = 'IKEA Translation Tool';
   workbook.lastModifiedBy = 'IKEA Translation Tool';
   workbook.created = new Date();
   workbook.modified = workbook.created;

   Object.entries(master).forEach(([section, labels]) => {
      const sheet = workbook.addWorksheet(section);
      sheet.columns = columns;

      sheet.getRow(1).eachCell(function (cell) {
         cell.style = {
            fill: {
               type: 'pattern',
               pattern: 'solid',
               fgColor: {
                  argb: 'FFEEEEEE'
               }
            },
            font: {
               name: 'Verdana',
               bold: true
            },
            border: {
               top: {
                  style: 'thin'
               },
               left: {
                  style: 'thin'
               },
               bottom: {
                  style: 'thin'
               },
               right: {
                  style: 'thin'
               }
            }
         };
      });

      const cellData = {
         localeValue: []
      };

      Object.entries(labels).forEach(([label, item]) => {
         const $locale = locale.slice(-2);

         if ((item.markets === '' || item.markets.split(',').some(market => market === $locale)) && !item.deprecated) {
            const localItem = local[section] && local[section][label] || { locale };
            const row = createRow(item, localItem, columns);
            sheet.addRow(row);
            cellData.localeValue.push(createLocaleValueCellData(item, localItem));
         }
      });

      columns.forEach(column => {
         sheet.getColumn(column.key).eachCell({ includeEmpty: true }, function (cell, row) {
            cell.alignment = {
               horizontal: 'left'
            };
            if (cellData.hasOwnProperty(column.key)) {
               // -2 because first row is header, and excel.js works with 1-based index
               if (cellData[column.key][row - 2]) {
                  cell = Object.assign(cell, cellData[column.key][row - 2]);
               }
            } else {
               Object.assign(cell, {
                  dataValidation: nonEditable(cell.address)
               });
            }
         });
      });
   });

   return await workbook.xlsx.writeBuffer({ base64: true });
}

function isOldFormat(worksheet) {
   return worksheet.columnCount == formats.OLD_FORMAT_COLUMN_COUNT;
}

async function parseWorkbook(application, locale, xlsx) {
   const workbook = new ExcelJS.Workbook();
   await workbook.xlsx.load(xlsx, { base64: true });

   return workbook.worksheets.reduce((obj, worksheet) => {
      obj[worksheet.name] = worksheet._rows.slice(1).reduce((keys, row) => {
         const indices = columns.reduce((indices, column, index) => {
            indices[column.key] = index + 1;
            return indices;
         }, {});

         if (isOldFormat(worksheet)) {
            // Read from last column
            indices.localeValue = worksheet.columnCount;
         }

         if (application !== row.values[indices.application]) {
            throw new Error('The selected workbook is for another application');
         }

         if (locale !== row.values[indices.locale]) {
            throw new Error('The selected workbook is for another locale');
         }

         const key = row.values[indices.label];
         const type = row.values[indices.type];
         let value = row.values[indices.localeValue];

         if (value && value.richText) {
            value = value.richText.reduce((str, rich) => str + rich.text, '');
         }

         if (type === 'boolean') {
            value = value === 'TRUE' ? true : false;
         }

         if (value == null) {
            value = null;
         }

         keys[key] = { value };

         return keys;
      }, {});

      return obj;
   }, {});
}

export default {
   createWorkbook,
   parseWorkbook
};

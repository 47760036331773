import '../../services/dialog.factory';

export default DialogDirective;

function DialogDirective (dialogFactory) {
   return {
      restrict: 'E',
      link: function(scope, element, attr) {
         const id = attr.dialogId;
         const options = dialogFactory.getOptions(id);
         options.close = exit;

         scope.getOptions = function() {
            return options;
         };

         scope.getOption = function(option) {
            return options[option];
         };

         scope.cancel = cancel;
         scope.proceed = proceed;

         element.addClass('active');
         element.on('click', function(e) {
            e.stopPropagation();
         });

         function proceed() {
            if (typeof options.proceedCallback === 'function') {
               options.proceedCallback();
            }
            exit();
         }

         function cancel() {
            if (typeof options.cancelCallback === 'function') {
               options.cancelCallback();
            }
            exit();
         }

         function exit() {
            element.removeClass('active');
            setTimeout(function() {
               dialogFactory.close(id);
            }, 250);
         }
      }
   };
}

DialogDirective.$inject = ['dialogFactory'];

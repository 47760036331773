import angular from 'angular';
import 'angular-route';

import './modules/core/styles/main.less';
import './modules/core/configs';
import './modules/core/styles';
import './modules/core/services/dialog.factory';
import './modules/core/services/routeService';
import './modules/core/services/sessionService';
import './modules/core/services/stateService';
import './modules/core/services/filterService';
import './modules/core/components/components.module';
import './modules/nav-menu/nav-menu.view';

import {view as authView} from './modules/auth/auth.view';
import {view as translateView } from './modules/translate/translate.module';
import {view as masterView} from './modules/master/master.module';
import {view as publishHistoryView} from './modules/publish-history/publish-history.view';
import {view as usersView} from './modules/users/users.module';
import {view as documentationView} from './modules/documentation/documentation.view';
import {view as adminView} from './modules/admin/admin.module';
import { modalView as loginView } from './modules/login/login.module';
import { forbiddenView } from './modules/errors/errors.module';
import constants from './modules/core/constants';

import 'core-js/stable';
import './polyfill';

import { ComponentManager } from '@ikea-aoa/ikea-shared-component';
import Scroll from '@ikea-aoa/ikea-component-scroll';
import Popup from '@ikea-aoa/ikea-component-popup';
import Toaster from '@ikea-aoa/ikea-component-toaster';

import './modules/core/services/analyticsService';

const componentManager = new ComponentManager();

componentManager.register(Scroll);
componentManager.register(Popup);
componentManager.register(Toaster);
componentManager.connectObserver();

const moduleName = 'app';
export default moduleName;

angular.module(moduleName, [
   'ngRoute',
   'components',
   'configs',
   'navMenu',
   'translate',
   'master',
   'publish-history',
   'users',
   'documentation',
   'admin',
   'auth',
   'stateService',
   'sessionService',
   'analyticsService'
])
   .config(['$routeProvider', function($routeProvider) {
      $routeProvider
         .when('/auth', authView)
         .when('/master', masterView)
         .when('/publish-history', publishHistoryView)
         .when('/users', usersView)
         .when('/documentation', documentationView)
         .when('/admin', adminView)
         .when('/translate', translateView)
         .when('/403', forbiddenView)
         .otherwise({
            redirectTo: '/translate'
         });
   }])
   .run(['$rootScope', 'analyticsService', function ($rootScope, analyticsService) {
      $rootScope.$on('$routeChangeSuccess', (e, current) => {
         const routeName = current.$$route.originalPath;
         if (routeName === '/auth') {
            return;
         }

         analyticsService.trackPage(routeName);
      });
   }])
   .controller('MainCtrl', Controller);

function Controller(routeService, stateService, sessionService, dialogFactory, newDomainModal, $window, $rootScope, $timeout) {
   const vm = this;

   vm.states = stateService.getStates();
   vm.constants = constants;

   vm.init = () => {
      const hostname = $window.location.hostname;
      const pathname = $window.location.pathname || '/translate';

      const env = getEnvironment(hostname, pathname);
      const versionName = getVersionName(pathname) || env;

      stateService.setState('environment', env);
      stateService.setState('versionName', versionName);

      sessionService.authenticate()
         .then(function() {
            redirectToView();
         }, function() {
            routeService.setPath('/auth');
            newDomainModal.create(hostname, pathname);
         });
   };

   ((timeout) => $rootScope.$watch(
      () => vm.states.authExpiresAtUTC,
      (curr) => {
         if (timeout) {
            $timeout.cancel(timeout);
            timeout = null;
         }
         if (curr) {
            const ms = curr*1000 - (new Date).getTime();
            timeout = $timeout(() => {
               stateService.setState('auth', constants.SESSION_TIMED_OUT);
               dialogFactory.custom(loginView)
            }, ms);
         }
      }
   ))();

   function redirectToView() {
      if (vm.isDialog) {
         dialogFactory.closeAll();
      } else {
         routeService.redirect();
      }
   }

   function getEnvironment(hostname, pathname) {
      switch (hostname) {
         case 'https://oversatta-dev-release-latest-1550128202.eu-west-1.elb.amazonaws.com/':
            return 'dev';
         case 'oversatta.services.inter.ikea.net':
            return 'prod';
         case 'oversatta.tools.inter.ikea.net':
            return 'prod';
         case 'ikea-aoa-translation.cybercomhosting.com':
            if (pathname.split('/')[1] === 'translate') {
               return 'prod';
            } else { return 'dev'; }
         default:
            return 'dev';
      }
   }

   function getVersionName(pathname) {
      const pathParts = pathname.split('/');
      if (pathParts[1] === 'release') {
         return pathname.substr(1).split('/')[1];
      }

      return null;
   }
}

Controller.$inject = [
   'routeService', 'stateService', 'sessionService', 'dialogFactory', 'newDomainModal', '$window', '$rootScope', '$timeout'
];

import template from './import-dialog.template.html';
import '../../core/services/dialog.factory';
import './import-dialog.less';
import './import-list/import-list.directive';

export const view = {
   template
};

export function ImportDialogCtrl($scope, dialogFactory) {
   const vm = this;
   vm.changedKeysToSave = [];
   vm.newKeysToSave = [];

   vm.init = init;
   vm.onClose = close;
   vm.onImport = onImport;
   vm.showNewKeys = true;
   vm.newKeys = [];
   vm.changedKeys = [];

   vm.newKeysClicked = () => {
      vm.showNewKeys = true;
   };

   vm.changedKeysClicked = () => {
      vm.showNewKeys = false;
   };

   vm.changedKeySelected = (changedKeys) => {
      vm.changedKeysToSave = changedKeys;
   };

   vm.newKeySelected = (newKeys) => {
      vm.newKeysToSave = newKeys;
   };

   Object.defineProperty(vm, 'totalSelectedAmount', {
      get () {
         return vm.newKeysToSave.length + vm.changedKeysToSave.length;
      }
   });

   function init(dialog) {
      vm.dialog = dialog;
      const initialMasterData = JSON.parse(JSON.stringify(vm.dialog.data.masterData));
      const importedData = vm.dialog.data.importData;

      const newKeysFromImport = getNewKeys(importedData, initialMasterData);
      const changedKeysFromImport = getChangedKeys(importedData, initialMasterData);

      vm.newKeys = newKeysFromImport;
      vm.changedKeys = changedKeysFromImport;
   }

   function getNewKeys(importData, masterData) {
      const newKeys = [];

      for (const section in importData) {
         for (const label in importData[section]) {
            if (!masterData[section] || (masterData[section] && !masterData[section][label])) {
               newKeys.push(Object.assign({}, importData[section][label], {
                  section,
                  label
               }));
            }
         }
      }

      return newKeys;
   }

   function getChangedKeys(importData, masterData) {
      const changedKeys = [];
      
      for (const section in importData) {
         for (const label in importData[section]) {
            if (!masterData[section] || !masterData[section][label]) {
               continue;
            }

            let oldValue   = masterData[section][label].value;
            let newValue   = importData[section][label].value;
            let oldMarkets = masterData[section][label].markets.split(',').sort().join(',');
            let newMarkets = importData[section][label].markets.split(',').sort().join(',');

            if (oldValue != newValue || oldMarkets != newMarkets) {
               changedKeys.push({
                  ...importData[section][label],
                  section,
                  label,
                  oldValue,
                  oldMarkets,
                  newMarkets
               });
            }

            continue;
         }
      }
      //console.timeEnd('time');

      return changedKeys;


   }

   function close() {
      vm.dialog.close();
   }

   function onImport() {
      dialogFactory.confirm({
         header: 'Confirm import',
         body: `${vm.totalSelectedAmount} of ${vm.newKeys.length + vm.changedKeys.length} keys will be imported.`,
         proceedText: 'Import',
         proceedCallback: () => {
            const importData = vm.dialog.data.importData;
            vm.dialog.close();
            vm.dialog.proceedCallback({
               new: vm.newKeysToSave.map(item => Object.assign({}, item, importData[item.section][item.label])),
               updated: vm.changedKeysToSave.map(item => Object.assign({}, item, importData[item.section][item.label]))
            });
         }
      });
   }
}

ImportDialogCtrl.$inject = [
   '$scope',
   'dialogFactory',
];

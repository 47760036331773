import './generic-button.less';

export default function genericButton() {
   return {
      restrict: 'E',
      transclude: true,
      scope: {
         onButtonClicked: '&',
         isLoading: '<',
         highlighted: '<',
         disabled: '<'
      },
      template: `
      <button ng-disabled="disabled" ng-click="onClick($event)" ng-class="{highlighted:highlighted, disabled: disabled, loading: isLoading}">
         <ng-transclude>
         </ng-transclude>
         <i ng-if="isLoading" class="IKEA--icon-loading-spinner"></i>
      </button>`,
      controller: ['$scope', ($scope) => {
         $scope.onClick = (event)=> {
            $scope.onButtonClicked({ event });
         };
      }]
   };
}

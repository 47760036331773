import template from './edit-master-item.template.html';
import './edit-master-item.less';
import 'core/services/dataService.js';
import 'core/services/stateService.js';
import '../../core/services/dialog.factory';

export const view = {
   template
};

export function EditMasterItemController($scope, dataService, stateService) {

   const vm = this;
   vm.dataTypes = {
      'string' : 'String',
      'integer': 'Integer',
      'boolean': 'Boolean',
      'select' : 'Select'
   };

   vm.states = stateService.getStates();
   vm.data = dataService.getData();
   vm.hasChanged = false;
   vm.allLabels = [];

   vm.init = init;
   vm.onItemChanged = onItemChanged;
   vm.onSelectType = onSelectType;
   vm.onAddOption = onAddOption;
   vm.onRemoveOption = onRemoveOption;
   vm.onLabelClick = onLabelClick;
   vm.onConfirm = confirmAndClose;
   vm.onCancel = close;
   vm.onAddLabel = onAddLabel;
   vm.calculateAllLabels = calculateAllLabels;
   vm.setDeprecated = setDeprecated;
   vm.onMarketClicked = onMarketClicked;

   Object.defineProperty(vm, 'isValid', {
      get: () => vm.label && vm.item.type
   });

   function init(dialog) {
      vm.dialog = dialog;
      vm.label = dialog.data.label;
      vm.markets = setMarkets();
      vm.removeEmptyStrings = vm.data.applications[vm.states.application].removeEmptyStrings;
      vm.item = Object.assign({}, dialog.data.item);
      vm.item.chars = vm.item.chars || 0;
      vm.item.options = vm.item.options || '';
      vm.item.labelGroup = vm.item.labelGroup || '';
      vm.item.markets = vm.item.markets || '';
      vm.item.approved = vm.item.approved ? true : false;
      vm.item.value = vm.item.value || '';
      vm.isDeprecated = dialog.data.item && dialog.data.item.deprecated ? true : false;
      vm.hasChanged = false;
      calculateAllLabels();
   }

   function setMarkets() {
      const markets = [];
      const countryCodes = {};
      for (const locale of vm.data.locales) {
         const codes = locale.value.split('_');
         const country = codes[1];
         const language = codes[0];
         if (!countryCodes[country]) {
            countryCodes[country] = locale.label.replace('(' + language + ')', '').replace('(en)', '').trim();
         }
      }
      for (const code in countryCodes) {
         markets.push({
            label: countryCodes[code],
            value: code
         });
      }

      return markets;
   }

   function onItemChanged () {
      //vm.data.local[section][key].value
      vm.item.changed = true; //item.value !== original;
      vm.hasChanged = true; //hasChanged();
   }

   function onSelectType(type) {
      vm.item.type = type;
      if (vm.item.type !== 'select') {
         vm.item.options = '';
      }
      
      if (vm.item.type === 'boolean') {
         vm.item.value = toBoolean(vm.item.value);
      } else if (vm.item.type === 'integer') {
         vm.item.value = toInteger(vm.item.value);
      }

      if (vm.item.type !== 'string') {
         vm.item.chars = null;
      }

      onItemChanged();
   }

   function onAddOption(option) {
      option = option.trim();

      if (!option.length) {
         return;
      }

      const current = vm.item.options.split(',').filter(v => v.length);

      if (current.includes(option)) {
         return;
      }

      const newOptions = current.concat([option]).join(',');

      if (newOptions.length > 255) {
         return;
      }

      vm.item.options = newOptions;
      onItemChanged();
   }

   function onRemoveOption(option) {
      const options = vm.item.options.trim().split(',');
      if (options.indexOf(option) > -1) {
         options.splice(options.indexOf(option), 1);
      }
      vm.item.options = options.join(',');
      onItemChanged();
   }

   function onMarketClicked(markets) {
      vm.item.markets =
         markets
            .map(market => market.value)
            .join(',');

      onItemChanged();
   }

   function onAddLabel(label) {
      vm.item.labelGroup = label;

      if (!vm.allLabels.includes(label)) {
         vm.allLabels.push(label);
      }

      onItemChanged();
   }

   function onLabelClick(label) {
      vm.item.labelGroup = vm.item.labelGroup === label ? 'Unlabeled' : label;
      onItemChanged();
   }

   function toBoolean(val) {
      if (val === false || val === 'false') {
         return false;
      }
      return true;
   }

   function toInteger(val) {
      if (isNaN(val)) {
         return 0;
      }
      return parseInt(Number(val), 10);
   }

   function close() {
      vm.dialog.close();
   }

   function calculateAllLabels () {
      vm.allLabels = [];

      for (const section in vm.data.masterGroups) {
         if (vm.data.masterGroups.hasOwnProperty(section)) {
            for (const labelItem of vm.data.masterGroups[section]) {
               if (!vm.allLabels.includes(labelItem.label) && labelItem.label !== 'Unlabeled') {
                  vm.allLabels.push(labelItem.label);
               }
            }
         }
      }

      vm.allLabels.sort();
   }

   function confirmAndClose() {
      vm.dialog.proceedCallback({
         section: {
            key: vm.dialog.data.section
         },
         label: vm.dialog.data.label || vm.label,
         item: vm.item
      });
      vm.dialog.close();
   }

   function setDeprecated(value) {
      vm.item.deprecated = value;
      vm.isDeprecated = vm.item.deprecated ? true : false;
      onItemChanged();
   }
}

EditMasterItemController.$inject = [
   '$scope',
   'dataService',
   'stateService',
   'dialogFactory'
];

export function AddOptionDirective($timeout) {
   return {
      restrict: 'E',
      scope: {
         item: '=',
         onAddOption: '=',
         autocomplete: '='
      },
      link: function(scope, element) {
         element.on('click', () => {
            const li = document.createElement('li');
            const input = document.createElement('input');
            const addOption = () => {
               const option = input.value.replace(/,/g, '');

               if (li.parentNode) {
                  li.parentNode.removeChild(li);
               }

               if (option !== '') {
                  scope.onAddOption(option);
                  $timeout(function() {
                     scope.$apply();
                  }, 0);
               }
            };

            if (scope.autocomplete) {
               const datalist = document.createElement('datalist');
               const items = [];
               datalist.id = 'datalist';
               for (const item of scope.autocomplete) {
                  if (item.label) {
                     const option = document.createElement('option');
                     option.value = item.label;
                     datalist.appendChild(option);
                     items.push(item.label);
                  }
               }
               li.appendChild(datalist);
               input.addEventListener('input', () => {
                  if (items.includes(input.value)) {
                     input.blur();
                  }
               });
            }

            li.className = 'add-option-input';
            li.appendChild(input);

            element[0].parentNode.parentNode.insertBefore(li, element[0].parentNode);

            input.select();

            input.addEventListener('blur', addOption);
            input.addEventListener('keydown', event => {
               if (scope.autocomplete) {
                  if (input.value.length > 0) {
                     input.setAttribute('list', 'datalist');
                  } else {
                     input.removeAttribute('list');
                  }
               }
               if (event.keyCode === 13) {
                  event.preventDefault();
                  input.blur();
               }
            });
            input.addEventListener('keyup', event => {
               if (event.target.value.length > 255) {
                  event.target.value = event.target.value.slice(0, 255);
               }
            });
         });
      }
   };
}

AddOptionDirective.$inject = ['$timeout'];


export default class TypeUtils {

   static evalType(value) {
      if (value === true || value === false
      || value === 'true' || value === 'false') {
         return 'boolean';
      }
      if (!isNaN(value) && Number(value) === parseInt(value)) {
         return 'integer';
      }
      return 'string';
   }

   static formatValue(value, type) {
      switch (type) {
         case 'integer':
            return parseInt(value);
         case 'boolean':
            return value === true || value === 'true' ? true : false;
         default:
            return String(value);
      }
   }

   static isValid(value, type, options) {
      switch (type) {
         case 'integer':
            return !isNaN(value) && Number(value) === parseInt(value);
         case 'boolean':
            return value === true || value === false;
         case 'string':
            return typeof value === 'string';
         case 'select':
            return options.indexOf(value) !== -1;
         default:
            return false;
      }
   }

}
import angular from 'angular';
import constants from '../constants';
import { decorateUser } from '../utils/UserUtils';

const moduleName = 'sessionService';
const user = {};

export default moduleName;

angular.module(moduleName, [
   'apiService',
   'stateService'
]).service(moduleName, sessionService);

function sessionService(apiService, stateService, newDomainModal, $window) {

   this.authenticate = authenticate;
   this.login = login;
   this.logout = logout;
   this.getUser = getUser;
   this.setUser = setUser;

   function authenticate() {
      const req = apiService.authenticate();
      stateService.setStates({
         auth: { value: constants.SESSION_UNKNOWN, store: false },
         loading: { value: true, store: false },
         redirect: { value: stateService.getState('path'), store: false }
      });
      req.then(onSessionData, onUnauthorized);
      return req;
   }

   function login(username, password) {
      const req = apiService.login(username, password);
      stateService.setState('loading', true);
      req.then(onSessionData, onUnauthorized);
      return req;
   }

   function logout() {
      const req = apiService.logout();
      req.then(onUnauthorized, onError);
      return req;
   }

   function onSessionData(xhr) {
      newDomainModal.create($window.location.hostname, $window.location.pathname);
      stateService.setStates({
         loading: { value: false, store: false },
         auth: { value: constants.SESSION_AUTHENTICATED, store: false },
         authExpiresAtUTC: { value: xhr.data.authExpiresAtUTC, store: false },
         loginFailCount: { value: 0, store: false }
      });
      delete xhr.data.authExpiresAtUTC;
      if (user.id && xhr.data.id !== user.id) {
         onUnauthorized();
         $window.location.reload();
      }
      setUser(xhr.data);
   }

   function onError() {
      stateService.setState('loading', false);
   }

   function getUser() {
      const copy = angular.copy(user);

      return decorateUser(copy);
   }

   function setUser(data) {
      Object.assign(user, angular.copy(data));
   }
   
   function onUnauthorized() {
      const loginFailCount = stateService.getState('loginFailCount');
      stateService.setStates({
         loading: { value: false, store: false },
         auth: { value: constants.SESSION_UNAUTHORIZED, store: false },
         authExpiresAtUTC: { value: null, store: false },
         loginFailCount: { value: loginFailCount + 1, store: false }
      });
      for (const prop in user) {
         delete user[prop];
      }
      stateService.clearLocalStorage();
   }

}

sessionService.$inject = ['apiService', 'stateService', 'newDomainModal', '$window'];

import './bubble.less';

function createBubble(message, element) {
   const bubble = document.createElement('bubble');
   const icon = document.createElement('i');

   icon.className = 'fa fa-caret-down icon-caret';
   bubble.className = 'bubble';
   bubble.textContent = message;
   bubble.appendChild(icon);

   if (message.length > 75) {
      bubble.classList.add('big');
   } else if (message.length > 55) {
      bubble.classList.add('medium');
   }

   element[0].parentNode.insertBefore(bubble, element[0]);
   element[0].parentNode.classList.add('bubble-wrapper');

   element[0].parentNode.addEventListener('mouseover', () => {
      const rect = element[0].getBoundingClientRect();
      bubble.style.top = rect.top + 'px';
   });

   return bubble;
}

export function BubbleDirective() {
   return {
      restrict: 'A',
      scope: {
         message: '<'
      },
      link: function(scope, element) {
         let bubble = null;

         scope.$watch('message', function () {
            if (bubble) {
               bubble.remove();
               bubble = null;
            }

            bubble = createBubble(scope.message, element);
         });
      }
   };
}

import template from './checkbox-list.template.html';
import './checkbox-list.less';

export function CheckboxListDirective() {
   return {
      restrict: 'E',
      scope: {
         items: '<',
         itemKey: '@',
         textKey: '@',
         selected: '=',
         hideSearch: '<',
         singleSelect: '<'
      },
      transclude: true,
      template,
      link: (scope) => {
         const itemKey = scope.itemKey || null;
         const textKey = scope.textKey || null;

         scope.search = {
            value: ''
         };

         scope.getText = item => textKey ? item[textKey] : item;

         scope.isSelected = item => scope.selected.find(sel => itemKey ? sel[itemKey] === item[itemKey] : sel === item);
         scope.isSomeSelected = () => scope.selected.length && scope.selected.length !== scope.items.length;
         scope.isAllSelected = () => scope.selected.length && scope.selected.length === scope.items.length;

         scope.onItemSelected = item => {
            if (scope.singleSelect) {
               scope.selected = scope.selected && scope.selected[itemKey] === item[itemKey] ? [] : [item];
            } else {
               if (scope.isSelected(item)) {
                  scope.selected = scope.selected.filter(sel => itemKey ? sel[itemKey] !== item[itemKey] : sel !== item);
               } else {
                  scope.selected.push(item);
               }
            }
         };

         scope.onSelectAll = () => {
            if (scope.isAllSelected()) {
               scope.selected = [];
            } else {
               scope.selected = scope.items.slice();
            }
         };

         Object.defineProperty(scope, 'filteredItems', {
            get () {
               const searchInput = scope.search.value.toLocaleLowerCase();
               return searchInput
                  ? scope.items.filter(item => (textKey ? item[textKey] : item).toLocaleLowerCase().indexOf(searchInput) > -1)
                  : scope.items;
            }
         });
      }
   };
}

import template from './radio-list.template.html';
import './radio-list.less';

export function RadioListDirective() {
   return {
      restrict: 'E',
      scope: {
         items: '<',
         itemKey: '@',
         textKey: '@',
         selected: '=',
         onValueChanged: '&'
      },
      transclude: true,
      template,
      link: ($scope) => {
         $scope.itemKey = $scope.itemKey || null;
         $scope.textKey = $scope.textKey || null;

         $scope.getText = item => $scope.textKey ? item[$scope.textKey] : item;
         $scope.getKey = item => $scope.itemKey ? item[$scope.itemKey] : item;

         $scope.radio = {
            value: $scope.selected && $scope.getKey($scope.selected)
         };

         $scope.$watch('radio.value', (newValue) => {
            $scope.onValueChanged && $scope.onValueChanged({ value: $scope.items.find(item => $scope.getKey(item) === newValue) });
         });
      }
   };
}


const constants = {
   MASTER_LOCALE: 'global',
   SESSION_UNKNOWN: 'unknown',
   SESSION_UNAUTHORIZED: 'unauthorized',
   SESSION_AUTHENTICATED: 'authenticated',
   SESSION_TIMED_OUT: 'session_timeout'
   
};

export default constants;

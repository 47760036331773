import 'core/services/dataService.js';
import './local-value-change.less';
import template from './local-value-change.template.html';

export const view = {
   template
};

export function localValueChangeController($scope, dataService) {

   const vm = this;

   vm.init = init;
   vm.data = dataService.getData();
   vm.left = '';
   vm.right = '';

   function init(dialog) {
      vm.dialog = dialog;
      vm.left = dialog.data.left;
      vm.right = dialog.data.right;
   }
}

localValueChangeController.$inject = [
   '$scope',
   'dataService'
];

import angular from 'angular';
import deployTargets from 'core/configs/deployTargets.js';
import 'core/services/apiService.js';
import 'core/services/dataService.js';
import 'core/services/sessionService.js';

import '../core/services/dialog.factory';
import template from './publish-history.template.html';
import './publish-history.less';

export const moduleName = 'publish-history';

export const view = {
   controller: PublishHistoryController,
   controllerAs: 'vm',
   template: template
};

angular.module(moduleName, [
   'components'
])
   .controller(moduleName, PublishHistoryController);

// const composedStatusText = {
//    in_progress: 'In progress',
//    completed: 'Completed',
//    failed: 'Failed/unknown'
// };

function PublishHistoryController($scope, $timeout, apiService, dataService, sessionService, routeService, analyticsService) {

   const vm = this;
   const data = dataService.getData();
   const user = sessionService.getUser();
   const ikeaLocales = dataService.getData().locales;
   let refresh = null;

   vm.filteredResults = {};
   vm.hiddenTabs = [];
   vm.visibleTabs = [];
   vm.tabSelected = 0;
   vm.filterMatchesForGroup = filterMatchesForGroup;
   vm.onTabSelected = onTabSelected;
   vm.getLocaleName = (locale) => {
      if (locale === 'global') {
         return `Master Copy (${locale})`;
      } else {
         return locale;
      }
   };
   vm.getStatusText = (status) => {
      return status[0].toUpperCase() + status.slice(1);
   };
   vm.getIcon = (status) => {
      switch (status) {
         case 'completed': return 'completed';
         case 'failed': return 'failed';
         default: return 'in_progress';
      }
   };

   vm.statusTabs = [
      {
         key: 'any',
         title: 'All'
      },
      {
         key: 'in_progress',
         title: 'In Progress'
      },
      {
         key: 'completed',
         title: 'Completed'
      },
      {
         key: 'failed',
         title: 'Failed'
      }
   ];

   function filterMatchesForGroup(tab) {
      return vm.filteredResults[tab];
   }

   function onTabSelected({ key, title }) {
      onSelectFilter('status', key, false);
      analyticsService.trackEvent('publish_history', 'change_tab', title);
   }

   vm.application = [];
   vm.locales = [];
   vm.environments = {
      any: 'Any'
   };

   vm.init = init;
   vm.onSelectFilter = onSelectFilter;
   vm.onRefresh = () => {
      getBuilds();
      analyticsService.trackEvent('publish_history', 'refresh_publications');
   };
   vm.showStartedByColumn = user.can('read_full', 'publish_history');

   $scope.$on('$destroy', function() {
      $timeout.cancel(refresh);
   });

   function init() {
      vm.filters = {
         application: 'any',
         locale: 'any',
         environment: 'any',
         status: 'any'
      };
      setApplications();
      setLocales();
      setDeployTargets();
      getBuilds();
      console.log(vm.environments);
   }

   function setApplications() {
      vm.applications = [{
         id: 'any',
         name: 'Any'
      }];
      
      dataService.getApplications()
         .then(function() {
            vm.applicationNames = data.applications;
            for (const app in data.applications) {
               vm.applications.push({
                  id: app,
                  name: data.applications[app].displayName
               });
            }
         });
   }

   function setLocales() {
      vm.locales = [
         { value: 'any', label: 'Any' },
         { value: 'global', label: 'Master Copy (global)' }
      ];

      if (user.locales.length) {
         for (const locale of ikeaLocales) {
            if (user.locales.indexOf(locale.value) > -1) {
               vm.locales.push(locale);
            }
         } 
      }

      vm.filters.locale = vm.locales[0].value;
   }

   function onSelectFilter(prop, value, reload) {
      vm.filters[prop] = value;

      if (reload) {
         getBuilds();
      }

      analyticsService.trackEvent('publish_history', `change_${prop}`, value);
   }

   function setDeployTargets() {
      for (const target of deployTargets) {
         vm.environments['publish-translations-' + target.id] = target.label;
      }
   }

   function getBuilds() {
      if (!vm.buildList) {
         vm.isLoading = true;
      }
      
      apiService.getBuildHistory({
         application: vm.filters.application === 'any' ? null : vm.filters.application,
         locale: vm.filters.locale === 'any' ? null : vm.filters.locale,
         job: vm.filters.environment === 'any' ? null : vm.filters.environment
      })
         .then(xhr => {
            const buildList = {};
            buildList.any = [];
            buildList.completed = [];
            buildList.failed = [];
            buildList.in_progress = [];
            for (const item of xhr.data.Results) {
               item.localeName = getLocaleName(item.locale);
               item.environment = vm.environments[item.job];
               // item.composedStatus = getComposedStatus(item.status);
               // item.statusText = composedStatusText[item.composedStatus];
               buildList.any.push(item);
               if (item.status === 'completed') {
                  buildList.completed.push(item);
               } else if (item.status === 'failed') {
                  buildList.failed.push(item);
               } else {
                  buildList.in_progress.push(item);
               }
            }
            vm.buildList = buildList;

            vm.statusTabs.forEach((tab) => {
               tab.bubblePrimary = vm.buildList[tab.key].length;
            });

            vm.isLoading = false;
            if (buildList.in_progress.length > 0) {
               getUpdatedBuilds();
            } else {
               $timeout.cancel(refresh);
            }
         });
   }

   function getUpdatedBuilds() {
      $timeout.cancel(refresh);

      // It seems like the destroy event is not always
      // triggered when quickly switching between tabs?
      // So we need an extra check here before re-running
      // the timeout.
      if (routeService.getPath() !== '/publish-history') {
         return;
      }

      refresh = $timeout(getBuilds, 5000);
   }

   function getLocaleName(locale) {
      for (const item of ikeaLocales) {
         if (item.value === locale) {
            return item.label;
         }
      }
      return locale;
   }

   // function getComposedStatus(status) {
   //    if (status === 'completed') {
   //       return 'completed';
   //    }
   //    if (/(started|queued|built)/.test(status)) {
   //       return 'in_progress';
   //    }
   //    return 'failed';
   // }

}

PublishHistoryController.$inject = [
   '$scope',
   '$timeout',
   'apiService',
   'dataService',
   'sessionService',
   'routeService',
   'analyticsService'
];

import angular from 'angular';
import dataService from './dataService';
import stateService from './stateService';

const moduleName = 'filterService';
export default moduleName;

angular.module(moduleName, [
   dataService,
   stateService
])
   .service(moduleName, filterService);

function filterService(
   dataService,
   stateService
) {

   const { searchString, newOrUpdated, itemStatus } = stateService.getStates();
   this.filters = {searchString, newOrUpdated, itemStatus};
   this.isAnyFilterActive = isAnyFilterActive;
   this.onChangeFilter = onChangeFilter;
   this.onResetFilters = onResetFilters;
   this.getFilters = getFilters;
   this.resetFilter = resetFilter;
   this.isFilterActive = isFilterActive;
   this.validFilters = {
      translate: ['searchString', 'newOrUpdated'],
      master: ['searchString', 'itemStatus']
   };

   function isAnyFilterActive(filters, view) {
      for (const filter of this.validFilters[view]) {
         if (this.filters[filter]) {
            return true;
         }
      }
      return false;
   }

   function isFilterActive(filter) {
      return Boolean(filter);
   }

   function onChangeFilter(filter, store) {
      if (this.filters[filter]) {
         stateService.setState(filter, this.filters[filter], store);
      } else {
         stateService.clearState(filter);
      }
      dataService.updateMasterGroups();
   }

   function onResetFilters() {
      Object.keys(this.filters).forEach(filter => this.resetFilter(filter));
      dataService.updateMasterGroups();
   }

   function resetFilter(filter) {
      this.filters[filter] = null;
      stateService.clearState(filter);
   }

   function getFilters() {
      return this.filters;
   }
}

filterService.$inject = [
   'dataService',
   'stateService'
];
export function decorateUser(user) {
   user.can = function (permission, resource) {
      return this.permissions[resource] && this.permissions[resource][permission] === true;
   };

   user.hasAccessToApp = function (application) {
      return !!this.applications.find(app => app.id === application);
   };

   user.hasAccessToLocale = function (locale) {
      return !!this.locales.find(loc => loc === locale);
   };

   return user;
}
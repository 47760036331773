import angular from 'angular';
import '../core/services/dialog.factory';
import './auth.less';
import template from './auth.template.html';

angular.module('auth', [
   'stateService',
   'components'])
   .controller('auth', AuthController);

export const view = {
   controller: AuthController,
   controllerAs: 'vm',
   template: template
};

function AuthController(stateService) {
   const vm = this;
   vm.states = stateService.getStates();
}

AuthController.$inject = [
   'stateService'
];

import { view as newDomainModal } from './modal/new-domain-modal.module';

export function newDomainModalFactory(dialogFactory) {
   return {
      create: (currentHostName, currentPathName) => {
         const legacyEnvironment = getLegacyEnvironment(currentHostName, currentPathName);

         if (legacyEnvironment) {
            dialogFactory.custom(Object.assign({
               header: 'New ÖVERSÄTTA URL',
               data: {
                  environment: legacyEnvironment
               },
               closeButton: false
            }, newDomainModal));
         }

         function getLegacyEnvironment(currentHostName, currentPathName) {
            const pathname = currentPathName || '/';
            switch (currentHostName) {
               case 'ikea-aoa-translation.cybercomhosting.com':
                  if (pathname.indexOf('/translate') === 0) {
                     return 'legacyProd';
                  } else if (pathname.indexOf('/test') === 0) {
                     return 'legacyTest';
                  }
            }

            return;
         }

      }
   };
}

newDomainModalFactory.$inject = ['dialogFactory'];
import template from './compare-revisions.template.html';
import 'core/services/dataService.js';
import '../../core/services/dialog.factory';
import './compare-revisions.less';
import './revision-list/revision-list.directive';
import './diffing-util.js';

export const view = {
   template
};

export function CompareRevisionsCtrl($scope, dataService, dialogFactory, diffingUtil, masterDataMappingUtil, analyticsService) {
   const vm = this;
   vm.init = init;
   vm.onClose = close;
   vm.toggleShowOnlyDiff = toggleShowOnlyDiff;

   function init(dialog) {
      vm.dialog = dialog;
      vm.revisionPrimary = vm.dialog.data.revisionsList[1].value;
      vm.revisionSecondary = vm.dialog.data.revision;
      getRevisionsInit(vm.revisionPrimary, vm.revisionSecondary);
   }

   const getRevisionsInit = (revisionPrimary, revisionSecondary) => {
      vm.commonTable = undefined;
      return loadRevisions(
         vm.dialog.data.application,
         vm.dialog.data.selectedTab,
         revisionPrimary,
         revisionSecondary
      )
         .then(masterDataMappingUtil.mapAllTablesLabelsOnGroup)
         .then(renderAsViewData);
   };

   vm.onPrimaryRevisionChange =
   vm.onSecondaryRevisionChange = (primary, secondary) => getRevisionsInit(primary, secondary).then(() => {
      analyticsService.trackEvent('master', 'compare_revision', 'change_revision');
   });

   function close() {
      vm.dialog.close();
   }

   function toggleShowOnlyDiff() {
      vm.showOnlyDiff = vm.showOnlyDiff ? false : true;
      analyticsService.trackEvent('master', 'compare_revision', 'toggle_show_only_changed');
   }

   function loadRevisions(application, selectedTab, revisionPrimaryTable, revisionSecondaryTable) {
      return Promise.all([
         loadTable(application, selectedTab, revisionPrimaryTable),
         loadTable(application, selectedTab, revisionSecondaryTable)
      ]).then((promisesResults) => {
         console.log('promisesResults', promisesResults);
         return {
            primaryTableOnGroups: promisesResults[0],
            secondaryTableOnGroups: promisesResults[1]
         };
      });
   }

   function loadTable(application, selectedTab, revision) {
      return dataService.getMasterRevision(application, revision)
         .then((xhr) => xhr.data[selectedTab]);
   }

   function renderAsViewData(tables) {
      vm.commonTable = diffingUtil.squashTablesToCommon(tables);

      /*
         The Promise API is not fully integrated with angularjs,
         making it neccesarry to trigger digest manually here,
         to update the DOM.
      */

      $scope.$digest();
   }
}

CompareRevisionsCtrl.$inject = [
   '$scope',
   'dataService',
   'dialogFactory',
   'diffingUtil',
   'masterDataMappingUtil',
   'analyticsService',
];

import "./tab-view.less";

export function TabViewDirective(dataService, stateService) {
   return {
      restrict: "E",
      scope: {
         tabs: "=",
         tabSelected: "=",
         filteredResults: "=",
         onChangeFilter: "=",
         onResetFilters: "=",
         filterMatchesForGroup: "=",
         filterService: "=",
         data: "=",
         onAddSection: "&",
         onTabSelected: "&",
         showListLength: "=",
         disableAddButton: "=",
      },
      transclude: true,
      template: `
      <ul class="tabs"
      data-cy="tabs">
         <li data-cy="section-{{section.title}}" ng-repeat="section in tabs track by $index" ng-click="onSelectTab(section)" ng-class="{active: tabSelected.key===section.key, 'greyed-out': filterService.isFilterActive(filters.searchString) && filterMatchesForGroup(section).length === 0}">
            <span>{{section.title}}<i ng-click="onRemoveSection(section.key)" ng-if="data && data.master && tabSelected.key===section.key && countObjProps(data.master[section.key], section.key, data) === 0" class="fa fa-trash icon-remove-section"></i></span>
            <span class="filter-result-counter" ng-if="showListLength && section.listLength > 0">({{section.listLength}})</span>
            <div class="tab-counter" ng-if="section.bubblePrimary || section.bubbleSecondary">
                  <span data-cy="{{section.title}}NewCount" class="tab-count unset {{ section.key }}" ng-if="section.bubblePrimary > 0" title="New">{{ section.bubblePrimary }}</span>
                  <span data-cy="{{section.title}}UpdatedCount" class="tab-count updated {{ section.key }}" ng-if="section.bubbleSecondary" title="Changed">{{ section.bubbleSecondary }}</span>
            </div>
         </li>
         <li 
         data-cy="{{section.title}}" ng-if="hiddenTabs.length" ng-click="toggleDropdown()" class="tab-dropdown" ng-class="{active: dropDownActive}">
            <span class="more-tabs">{{hiddenTabs.length}} More tabs... <i class="fa fa-angle-double-right"></i></span>
            <div class="dropdown" ng-class="{visible: dropDownActive}">
               <div class="dropdown-items" ng-class="{visible: dropDownActive}">
               <div class="dropdown-item" ng-repeat="section in hiddenTabs track by $index" ng-click="onSelectTab(section)" ng-if="section.key !== tabSelected.key">
                  <span>{{section.title}}</span>
               </div>
               </div>
            </div>
         </li>
         <li ng-if="showAddButton === true" ng-click="onAddSection()">
            <span><i class="fa fa-plus icon-add-section"></i></span>
         </li>
      </ul>
      <div ng-transclude></div>`,
      link: function (scope) {
         const maxTabs = Math.floor(window.screen.availWidth / 180);

         scope.hiddenTabs = [];

         scope.tabs = scope.tabs.filter((tab, index) => {
            if (index >= maxTabs) {
               scope.hiddenTabs.push(tab);
               return false;
            } else {
               return true;
            }
         });

         scope.onRemoveSection = (section) => {
            // Set selected tab to the one
            // on the left of deleted tab.
            const index = scope.tabs.findIndex((t) => t.key === section);
            const previous = Math.max(0, index - 1);
            scope.tabSelected = scope.tabs[previous];

            delete scope.data.master[section];
            stateService.setState("hasChanges", true);
            dataService.updateMasterGroups();
         };

         scope.countObjProps = (obj) => {
            return Object.keys(obj).length;
         };

         if (scope.onAddSection && !scope.disableAddButton) {
            scope.showAddButton = true;
         }

         (function () {
            scope.$watch("data.masterGroups", onMasterGroupChanged);
         })();

         scope.onSelectTab = function (tab) {
            scope.tabSelected = tab;
            scope.onTabSelected({ tab });
            recalculateTabs(tab);
         };

         if (scope.tabs.indexOf(scope.tabSelected) < 0) {
            scope.tabSelected = scope.tabs[0];
         }

         function recalculateTabs(clickedTab) {
            const isTabInHiddenTabsSection = scope.hiddenTabs.some(
               (tab) => tab === clickedTab
            );
            if (isTabInHiddenTabsSection) {
               const lastVisibleTab = scope.tabs.pop();
               scope.tabs.push(clickedTab);
               scope.hiddenTabs = scope.hiddenTabs.filter(
                  (tab) => tab !== clickedTab
               );
               scope.hiddenTabs.push(lastVisibleTab);
            }
         }

         scope.getSelectedTabValue = function () {
            return Object.keys(scope.data.master)[scope.tabSelected];
         };

         scope.toggleDropdown = function () {
            scope.dropDownActive = !scope.dropDownActive;
         };

         function onMasterGroupChanged() {
            updateFilteredResultsPerGroup();
         }

         function updateFilteredResultsPerGroup() {
            const tempObj = Object.assign(
               {},
               dataService.getData().masterGroups
            );
            Object.keys(tempObj).forEach((key) => {
               tempObj[key] = Object.values(tempObj[key])
                  .map((val) => val.items)
                  .reduce((prev, curr) => prev.concat(curr), []);
            });
            scope.filteredResults = Object.assign({}, tempObj);
         }
      },
   };
}

TabViewDirective.$inject = ["dataService", "stateService"];
